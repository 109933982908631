import {Breadcrumbs, Card, Link, Typography} from "@material-ui/core";
import React, {Component} from "react";
import app from '../../firebase/firebaseConfig';
import Button from "@material-ui/core/Button";
import {DataGrid} from "@material-ui/data-grid";



class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersData: {},
            userInfo: [],
            id: false
        }

        this.columns = [
            {
                field: 'id',
                hide: true,
            },
            {
                field: "name",
                headerName: "Name",
                width: 250,
                // minWidth:'300px',
                // maxWidth:'300px',
                editable: false,
            },
            {
                field: "Userid",
                headerName: "UserId",
                width: 350,
                editable: false,
            },
            {
                field: "credit",
                headerName: "Credit",
                width: 200,
                editable: true,
            },
            {
                field: "phone",
                headerName: "Phone",
                width: 120,
                editable: false,
            },
            {
                field: "listings",
                headerName: "Total ads credits",
                width: 120,
                editable: false,
            },
            {
                field: "actions",
                headerName: "Stories",
                type:'actions',
                width: 120,
                renderCell: this.renderDetailsButton.bind(this),
                editable: false
            }
        ];

    }

    renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.clickHandler(params)}
                >
                    View Stories
                </Button>
            </strong>
        )
    }


    componentDidMount = () => {
        app.database().ref('users').on('value', (snapShot) => {
            if (snapShot.exists()) {
                this.setState({userData: snapShot.val()}, () => {
                    let userInfo = []
                    for (const [key, value] of Object.entries(snapShot.val())) {
                        let count = 0;
                        if (value.listings !== undefined) {
                            for (const objValue of Object.entries(value.listings)) {
                                if (objValue[1].listingStory !== undefined) {
                                    let count1 = Object.keys(objValue[1].listingStory).length;
                                    count = count + count1;
                                }
                            }
                        }
                        let obj = {
                            id:key,
                            Userid: key,
                            name: value.name ? value.name : '',
                            credit: value.credit ? value.credit : '',
                            phone: value.phone ? value.phone : '',
                            listings: count,

                        }
                        userInfo.push(obj)
                    }
                    let filterInfo = userInfo.filter(f => f.name !== '' && f.credit !== '')
                    this.setState({userInfo: [...filterInfo]})
                })
            }
        })
    };


    updateUserInfo = (params) => {
        this.updateApi(params.id, params.field, params.value)
    }

    updateApi = (id, field, value) => {
        app.database().ref(`users/${id}/${field}`).set(value).then(() => {
        })
    }

    clickHandler = (params) => {
        this.props.history.push(`/viewListings/${params.id}`);
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link color="inherit" href="/#/">
                        <Typography variant="caption">Home</Typography>
                    </Link>
                    <Link color="inherit" href="/admin#/wallet">
                        <Typography variant="caption">Wallet Management</Typography>
                    </Link>
                </Breadcrumbs>
                <div title="Wallet Management" style={{display: "flex", flex: 1}}>
                    <Card style={{display: "flex", flex: 1}}>
                        <div style={{width: "100%", marginTop: '3%'}}>
                            <DataGrid
                                rows={this.state.userInfo}
                                columns={this.columns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                checkboxSelection
                                disableSelectionOnClick
                                onCellEditCommit={this.updateUserInfo}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default Wallet;
