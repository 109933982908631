import React from 'react'
const Home = () => {

    return (
        <div className="mainContainer">
            <div className="off_canvars_overlay">

            </div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="offcanvas_menu_wrapper">
                                <div className="canvas_close">
                                    <a href="javascript:void(0)"><i className="fal fa-times"></i></a>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li><a href="https://www.linkedin.com/company/madmaps-apps" target="_blank"><i
                                            className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li><a href="https://instagram.com/_madmaps" target="_blank"><i
                                            className="fab fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li className="menu-item-has-children active">
                                            <a className="page-scroll" href="#home">Home</a>
                                        </li>
                                        <li className="menu-item-has-children ">
                                            <a className="page-scroll" href="#intro">Features</a>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a className="page-scroll" href="#preview">Preview</a>
                                        </li>
                                        <li className="menu-item-has-children ">
                                            <a className="page-scroll" href="#faqs">FAQs</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link page-scroll" href="/privacy-policy">Privacy
                                                Policy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link page-scroll" href="/terms-of-use">Terms of
                                                use</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link page-scroll" href="/end-user-license-agreement">End user license agreement</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header id="home" className="header-area header-v1-area">
                <div className="header-nav" id="fc-header-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="navigation">
                                    <nav className="navbar navbar-expand-lg navbar-light ">
                                        <a className="navbar-brand" href="/"><img
                                            src="assets2/images/madmapswebsite/Logo-new.png?v=2" alt=""/></a>
                                        <span className="side-menu__toggler canvas_open"><i className="fa fa-bars"></i></span>

                                        <div className="collapse navbar-collapse sub-menu-bar main-nav__main-navigation"
                                             id="navbarSupportedContent">
                                            <ul className="navbar-nav ml-auto main-nav__navigation-box">
                                                <li className="nav-item active">
                                                    <a className="nav-link page-scroll" href="#home">Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="#intro">Features</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="#preview">Preview</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="#faqs">FAQs</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="/privacy-policy">Privacy
                                                        Policy</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="/terms-of-use">Terms
                                                        of use</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link page-scroll" href="/end-user-license-agreement">End user license agreement</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="hero-area bg_cover" id="hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="hero-content">
                                <h1 className="title"><span>Discover</span> what’s happening where.</h1>
                                <p>Watch real time ads on a map.</p>
                                <ul className="nav">
                                    <li><a className="main-btn"
                                           href="https://bit.ly/madmaps-android"
                                           target="_blank">Download <i className="fas fa-angle-right"></i></a></li>
                                    <li><a className="main-btn main-btn-2 " href="#blog">Get in touch <i
                                        className="fas fa-angle-right"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-thumb fc-hero-thumb" id="hero-thumbnail">
                                <img src="assets2/images/phonemockup/home-banner.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="intro" className="features-area bg_cover">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="features-box">
                                <div className="features-title">
                                    <h3 className="title">The best way to explore.</h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="features-item mt-30">
                                            <img src="assets2/images/madmapswebsite/Real-time-ads.png" alt="" />
                                                <h4 className="title">Real time ads</h4>
                                                <p>
                                                    As a platform, we enable local businesses like restaurants, cafes, bars, boutiques, craft shops, and others to register with us. Once done, one can upload ad stories directly for locals and travelers to see.
                                                </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="features-item mt-30">
                                            <img src="assets2/images/madmapswebsite/Hyper--local-discoveries-made-easy.png" alt="" />
                                                <h4 className="title">Hyper- local discoveries made easy</h4>
                                                <p>
                                                    App users will have access to a map that not only tells them what's where, but also what is happening there.
                                                </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="features-item mt-30">
                                            <img src="assets2/images/madmapswebsite/Local-content.png" alt="" />
                                                <h4 className="title">Local content</h4>
                                                <p>
                                                    In our Mad Library, one can explore short and snackable location tours, video stories, and photo stories.
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="features-play">
                                    <a className="video-popup" href="https://www.youtube.com/watch?v=5-MXyRnXf0Y"><i
                                        className="fas fa-play"></i></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="preview" className="screenshot-area bg_cover">
                <div className="container">
                    <div className="row">
                        <div className="section-title">
                            <div className="col-lg-6">
                                <h3 className="title">Have a look at what’s inside the app.</h3>
                            </div>
                            <div className="screenshot-arrows">
                                <span className="prev"><i className="far fa-arrow-circle-left"></i></span>
                                <span className="next"><i className="far fa-arrow-circle-right"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <div className="row screenshot-active position-relative">
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/1.png?v=1" alt="screenshot" />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/4.png?v=1" alt="screenshot" />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/5.png?v=1" alt="screenshot"/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/6.png?v=1" alt="screenshot"/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/7.png?v=1" alt="screenshot"/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/8.png?v=1" alt="screenshot"/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/9.png?v=1" alt="screenshot"/>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="screenshot-item mt-30">
                                <img className='img-fluid' src="assets2/images/phonemockup/10.png?v=1" alt="screenshot" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
</section>

            <section id="faqs" className="faq-area bg_cover">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-title my-5">
                                <h3 className="title">Some most frequently asked questions.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="faq-box">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="faq-accordion">
                                    <div className="accordion-grp" data-grp-name="faq-accordion">
                                        <div className="accordion active  animated wow fadeInRight"
                                             data-wow-duration="1500ms"
                                             data-wow-delay="0ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>What is different about MadMaps from other maps?</h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>Other Maps tell you what is located where. MadMaps also tells
                                                            you what is happening there, in real time, through ad
                                                            stories directly put up by business owners..</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion   animated wow fadeInRight" data-wow-duration="1500ms"
                                             data-wow-delay="300ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>Will I be able to register any kinds of business?</h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>As long as you have a business at a location, and are looking
                                                            for customers you will be able to register the same.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion  animated wow fadeInRight" data-wow-duration="1500ms"
                                             data-wow-delay="600ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>Can I register more than one business under one profile?</h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>Yes, you can register as many businesses you want to, under
                                                            one profile</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion  animated wow fadeInRight" data-wow-duration="1500ms"
                                             data-wow-delay="600ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>How many times can I upload an ad story?</h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>You can upload as many ad stories as you want. Each day,
                                                            Every day.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion  animated wow fadeInRight" data-wow-duration="1500ms"
                                             data-wow-delay="600ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>What does an ad story cost me?</h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>As a welcome package you have been given 3000 points in your
                                                            MadMaps wallet. Every ad story will cost you 30 points.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion  animated wow fadeInRight" data-wow-duration="1500ms"
                                             data-wow-delay="600ms">
                                            <div className="accordion-inner">
                                                <div className="accordion-title">
                                                    <h4>How will people find my ad story? </h4>
                                                </div>
                                                <div className="accordion-content">
                                                    <div className="inner">
                                                        <p>They will download the app, and log on to the map. The moment
                                                            they open the map, they will be able to see your last ad
                                                            story flashing on your location drop.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faq-play-extra">
                        <img src="assets2/images/madmapswebsite/faq-responsive.png" />
                    </div>
                </div>
                <div className="faq-shape"></div>
            </section>

            <section className="download-app-area ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="download-app-content">
                                <h3 className="title">Download now <br /> Make discovery really easy.</h3>
                                <ul className="nav">
                                    <li>
                                        <a className="main-btn main-btn-3"
                                           href="https://bit.ly/madmaps-android"
                                           target="_blank">Download <i className="fas fa-angle-right"></i></a>
                                    </li>
                                    <li><a className="main-btn main-btn-2 main-btn-4" href="#blog">Get in touch <i className="fas fa-angle-right"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="download-app-case">
                    <img src="assets2/images/madmapswebsite/download-app-case2.png" alt="" />
                </div>

            </section>

            <section id="blog" className="news-area">
                <div className="container">
                    <div className="section-title text-left">
                        <h3 className="title">Get in touch</h3>
                    </div>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-5 form-fields">
                            <ul>
                                <li className="py-3">
                                    <i className="fas fa-envelope"></i>Email <br />
                                    <a href="mailto:<nowiki>support@madmaps.in?"
                                       subject="subject text">support@madmaps.in</a>
                                </li>
                                <li className="py-3">
                                    <img src="https://img.icons8.com/ios-glyphs/50/000000/whatsapp.png"/>Whatsapp <br />
                                    <a href="tel:9818045110">+91-9818045110</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-5 form-img">
                            <img src="assets2/images/madmapswebsite/get-in-touch.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer-area">
                <div className="container">
                    <div className="footer-content">

                        <div className="footer-about mt-30">
                            <a href="#"><img src="assets2/images/madmapswebsite/Logo-new.png?v=1.1" alt="" /></a>
                            <ul>
                                <li><a href="https://www.linkedin.com/company/madmaps-apps" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                                <li><a href="https://instagram.com/_madmaps" target="_blank"><i className="fab fa-instagram"></i></a></li>

                            </ul>
                            <p className="pl-md-5 ml-md-5">@MadMaps-2021</p>
                        </div>

                    </div>
                </div>
            </section>

            <a className="back-to-top">
                <i className="fal fa-angle-up"></i>
            </a>

        </div>

    )
}

export default  Home