import Accordion from "@material-ui/core/Accordion";
import {
  Card,
  Link,
  Typography,
  Breadcrumbs,
  TextField,
  CardMedia,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Switch,
  ButtonBase,
  Grid,
} from "@material-ui/core";
import React from "react";
import app from "../../firebase/firebaseConfig";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import PaletteIcon from "@material-ui/icons/Palette";
import ImageUploader from "react-images-upload";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      open: false,
      locationOpen: false,
      categoryName: "",
      Locationname: "",
      Longitude: "",
      latitude: "",
      deltaLat : 0.7,
      deltaLng : 0.7,
      index: "",
      image: "",
      interestName: "",
      uploadedImg: "",
      background: "",
      displayColorPicker: false,
      color: "#fff",
      category: "",
      loading: false,
      confirmOpen:false,
      indexLocations:''
    };
  }

  componentDidMount = () => {
    app
      .database()
      .ref(`ui`)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          this.setState({ settings: snapshot.val() });
        }
      });
  };

  handlePickerClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handlePickerClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleImageSelect = (e) => {
    this.setState({ loading: true });
    let storageRef = app.storage().ref();
    let path = "";
    if (this.state.categoryName === "category") {
      path = "categoryIcon";
    }
    if (this.state.categoryName === "interests") {
      path = "interest";
    }
    let mountainImagesRef = storageRef.child(`/${path}/${e[0].name}`);
    mountainImagesRef.put(e[0]).then(() => {
      mountainImagesRef.getDownloadURL().then((url) => {
        this.setState({ image: url, loading: false });
      });
    });
  };

  handleClose = () => {
    this.setState({
      interestOpen: false,
      locationOpen: false,
      categoryOpen: false,
      confirmOpen:false
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleColorChange = (color) => {
    this.setState({ color: color.hex });
  };

  handleClickOpen = (k) => {
    if (k === "interests") {
      this.setState({ interestOpen: true });
    }
    if (k === "category") {
      this.setState({ categoryOpen: true });
    }
    if (k === "locations") {
      this.setState({ locationOpen: true });
    }
  };

  openformModal = (k) => {
    this.setState({ categoryName: k }, () => this.handleClickOpen(k));
  };

  handleRemoveCategory = (e, index) => {
    app
      .database()
      .ref(`ui/searchCat`)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          const array = snapshot.val();
          array.splice(index, 1);
          app.database().ref(`ui/searchCat`).set(array);
          this.handleClose();
        }
      });
  };

  handleLocationSubmit = () => {
    const {
      latitude,
      Longitude,
      Locationname,
      deltaLat,
      deltaLng,
      categoryName,
      category,
      interestName,
      image,
      color,
    } = this.state;
    let payload = {};

    if (categoryName === "locations") {
      if(latitude && Longitude &&  Locationname){
        payload.Lat = Number(latitude);
        payload.Lng = Number(Longitude);
        payload.deltaLat = Number(deltaLat)
        payload.deltaLng = Number(deltaLng)
        payload.name = Locationname;
        this.handleUpload(payload);
      }
    }
    if (categoryName === "category") {
      if(color && category &&  image){
      payload.color = color;
      payload.name = category;
      if (image) {
        payload.icon = image;
        this.handleUpload(payload);
      }
    }
    }
    if (categoryName === "interests") {
      if(image && interestName){
      const key = `${interestName[0].toUpperCase()}${interestName.substring(
        1
      )}`;
      payload.key = key;
      payload.flag = true;
      payload.img = image;
      this.handleUpload(payload);
    }
  }
  };

  handleGetLatLng = (address) => {
    geocodeByAddress(address.label)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      this.setState({latitude : lat, Longitude : lng})
      
    }
    );
  }

  handleUpload = (payload) => {
    const { categoryName } = this.state;
    if (categoryName !== "interests") {
      app
        .database()
        .ref(`ui/${categoryName === "category" ? "searchCat" : categoryName}`)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            let object = snapshot.val();
            let index = object?.length;
            app
              .database()
              .ref(
                `ui/${
                  categoryName === "category" ? "searchCat" : categoryName
                }/${index}`
              )
              .set(payload);
            this.handleClose();
          }
        });
    }
    if (categoryName === "interests") {
      const { key } = payload;
      const obj = {
        flag: payload.flag,
        img: payload.img,
      };
      app
        .database()
        .ref(`ui/${categoryName}`)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            let object = snapshot.val();
            object[key] = obj;
            app.database().ref(`ui/${categoryName}`).set(object);
            this.handleClose();
          }
        });
    }
  };

  deleteLocation = () => {
      app
        .database()
        .ref(`ui/locations`)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const array = snapshot.val();
            array.splice(this.state.indexLocations, 1);
            app.database().ref(`ui/locations`).set(array);
            this.handleClose();
          }
        });
    };

  // }
  render() {
    const styles = reactCSS({
      default: {
        color: {
          marginTop: "0.5rem",
          width: "8rem",
          height: "1.5rem",
          borderRadius: "2px",
          background: this.state.color,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "fixed",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link color="inherit" href="/#/ ">
            <Typography variant="caption">Home</Typography>
          </Link>
          <Typography variant="caption">Settings</Typography>
        </Breadcrumbs>
        <div title="Settings" style={{ display: "flex", flex: 1 }}>
          <Card
            style={{
              display: "flex",
              flex: 1,
              padding: 20,
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", gap: 50, height: "max-content" }}>
              <Typography style={{ alignSelf: "center" }}>
                Credit Deduct
              </Typography>
              <TextField
                variant="outlined"
                value={this.state.settings?.deduct?.credit}
                onChange={(e) => {
                  if (e.target.value !== null || e.target.value !== "") {
                    app
                      .database()
                      .ref(`ui/deduct`)
                      .update({ credit: e.target.value });
                  }
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1a-content"
                  aria-label="Expand"
                  id="additional-actions1-header"
                  aria-controls="additional-actions1-content"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography style={{ flex: 1 }}>Interests</Typography>

                  <FormControlLabel
                    aria-label="Button"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <ButtonBase
                        onClick={() => this.openformModal("interests")}
                      >
                        <AddCircleOutlineIcon />
                      </ButtonBase>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
                >
                  {this.state.settings.interests &&
                    Object.keys(this.state.settings?.interests).map((k, i) => {
                      return (
                        <Card
                          key={k}
                          style={{
                            padding: 10,
                            display: "inline-flex",
                            flex: "28%",
                            gap: 10,
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxWidth: "29.5%",
                          }}
                        >
                          <CardMedia
                            style={{ width: 80, height: 50 }}
                            image={this.state.settings.interests[k]["img"]}
                          />
                          <Typography>{k}</Typography>
                          <Switch
                            checked={this.state.settings.interests[k]["flag"]}
                            onChange={() => {
                              app.database().ref(`ui/interests/${k}`).update({
                                flag: !this.state.settings.interests[k]["flag"],
                              });
                            }}
                            name={k}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </Card>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1a-content"
                  aria-label="Expand"
                  id="additional-actions1-header"
                  aria-controls="additional-actions1-content"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography style={{ flex: 1 }}>Search Category</Typography>

                  <FormControlLabel
                    aria-label="Button"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <ButtonBase
                        onClick={() => this.openformModal("category")}
                      >
                        <AddCircleOutlineIcon />
                      </ButtonBase>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
                >
                  {this.state.settings.searchCat &&
                    this.state.settings?.searchCat.map((k, i) => {
                      return (
                        <Card
                          key={k.name}
                          style={{
                            padding: 10,
                            display: "inline-flex",
                            backgroundColor: k.color,
                            flex: "28%",
                            gap: 10,
                            alignItems: "center",
                            justifyContent: "space-between",
                            maxWidth: "29.5%",
                          }}
                        >
                          <CardMedia
                            style={{ width: 20, height: 20 }}
                            image={k.icon}
                          />
                          <Typography style={{ color: "white" }}>
                            {k.name}
                          </Typography>
                          <div
                            style={{ width: 30, height: 10, borderRadius: 15 }}
                          ></div>
                          <ButtonBase
                            onClick={() => this.handleRemoveCategory(k, i)}
                            style={{ color: "white" }}
                          >
                            <RemoveCircleOutlineIcon />
                          </ButtonBase>
                        </Card>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1a-content"
                  aria-label="Expand"
                  id="additional-actions1-header"
                  aria-controls="additional-actions1-content"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography style={{ flex: 1 }}>Locations</Typography>

                  <FormControlLabel
                    aria-label="Button"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <ButtonBase
                        onClick={() => this.openformModal("locations")}
                      >
                        <AddCircleOutlineIcon />
                      </ButtonBase>
                    }
                  />
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
                >
                  {this.state.settings.locations &&
                    this.state.settings?.locations.map((k, i) => {
                      return (
                        <Card
                          key={k.name}
                          style={{
                            padding: 10,
                            display: "inline-flex",
                            flex: "33%",
                            gap: 10,
                            flexDirection: "column",
                            justifyContent: "space-between",
                            maxWidth: "47%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <Typography style={{ flex: 1 }}>
                              Location name
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={k.name}
                              onBlur={(e) => {
                                if (e.target.value !== null || e.target.value !== "") {
                                app
                                  .database()
                                  .ref(`ui/locations/${i}`)
                                  .update({ name: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <Typography>Latitude</Typography>
                            <TextField
                              variant="outlined"
                              value={k?.Lat}
                              onChange={(e) => {
                                let data =this.state.settings
                                data.locations[i].Lat = e.target.value
                                this.setState({settings : data})
                              }}
                              onBlur={(e) => {
                                if (e.target.value !== null || e.target.value !== "") {
                                  app
                                    .database()
                                    .ref(`ui/locations/${i}`)
                                    .update({ Lat: parseFloat(e.target.value) });
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <Typography>Longitude</Typography>
                            <TextField
                              variant="outlined"
                              value={k?.Lng}
                              onChange={(e) => {
                                let data =this.state.settings
                                data.locations[i].Lng = e.target.value
                                this.setState({settings : data})
                              }}
                              onBlur={(e) => {
                                if (e.target.value !== null || e.target.value !== "") {
                                  app
                                    .database()
                                    .ref(`ui/locations/${i}`)
                                    .update({ Lng: parseFloat(e.target.value) });
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <Typography>Delta Latitude</Typography>
                            <TextField
                              variant="outlined"
                              value={k?.deltaLat}
                              onChange={(e) => {
                                let data =this.state.settings
                                data.locations[i].deltaLat = e.target.value
                                this.setState({settings : data})
                              }}
                              onBlur = {(e) => {
                                if (e.target.value !== null || e.target.value !== "") {
                                  app
                                    .database()
                                    .ref(`ui/locations/${i}`)
                                    .update({ deltaLat: parseFloat(e.target.value) });
                                }
                              }

                              }
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                            }}
                          >
                            <Typography>Delta Longitude</Typography>
                            <TextField
                              variant="outlined"
                              value={k?.deltaLng}
                              onChange={(e) => {
                                let data =this.state.settings
                                data.locations[i].deltaLng = e.target.value
                                this.setState({settings : data})
                              }}
                              onBlur={(e) => {
                                if (e.target.value !== null || e.target.value !== "") {
                                  app
                                    .database()
                                    .ref(`ui/locations/${i}`)
                                    .update({ deltaLng: parseFloat(e.target.value) });
                                }
                              }}
                            />
                          </div>
                          <Button
                            type="button"
                            variant="contained" color="primary"
                            style={{width: '16%',
                             }}
                            onClick={()=>this.setState({confirmOpen:true,indexLocations:i})}
                          >
                            Delete
                          </Button>
                        </Card>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            </div>
          </Card>

          {/************************** Interest modal  ********************************* */}

          <Dialog
            open={this.state.interestOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{
                background: "#FDBA27",
                color: "white",
                minWidth: "30rem",
              }}
            >
              <label className="modalheading">{"Settings"}</label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Add {this.state.categoryName}</b>
              </DialogContentText>
              <TextField
                margin="dense"
                id="interestName"
                name="interestName"
                label="Interest Name"
                type="text"
                onChange={this.handleChange}
                fullWidth
                required={true}
              />
              <div className="row" style={{ marginTop: "2%" }}>
                <div className="col-sm-6">
                  <label>Upload Image</label>
                </div>
                <div className="col-sm-6">
                  <ImageUploader
                    id="file"
                    placeholder="Upload Image"
                    type="file"
                    onChange={this.handleImageSelect}
                  />
                </div>
              </div>
              {this.state.loading ? (
                <Grid container spacing={2}>
                  <Grid item xs={3} />

                  <Grid item xs={9} style={{ color: "red" }}>
                    Uploading Image! Please wait.....
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleLocationSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/************************** location modal  ********************************* */}

          <Dialog
            open={this.state.locationOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{
                background: "#FDBA27",
                color: "white",
                minWidth: "30rem",
              }}
            >
              <label className="modalheading">{"Settings"}</label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Add Location</b>
              </DialogContentText>
              <TextField
                margin="dense"
                id="Locationname"
                name="Locationname"
                label="Location Name"
                type="text"
                onChange={this.handleChange}
                fullWidth
                required
              />
              <div>
              <p>Search Location</p>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCWA8SQYhCg5PW8JZFRvIuSWrvC5nSLYKc"
                    apiOptions={{ language: 'en', region: 'in' }}
                    minLengthAutocomplete={3}
                    withSessionToken
                    selectProps={{onChange : (value) => this.handleGetLatLng(value)}}
                  />
              </div>
              <TextField
                margin="dense"
                id="Longitude"
                name="Longitude"
                label="Longitude"
                type="number"
                value={this.state.Longitude}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="deltaLat"
                name="deltaLat"
                label="Delta Latitude (0 - 1 for ex 0.2)"
                type="number"
                value={this.state.deltaLat}
                onChange={this.handleChange}
                fullWidth
                required
              />
              <TextField
                margin="dense"
                id="deltaLng"
                name="deltaLng"
                label="Delta Longitude (0 - 1 for ex 0.2)"
                type="number"
                value={this.state.deltaLng}
                onChange={this.handleChange}
                fullWidth
                required
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleLocationSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/************************** category modal  ********************************* */}

          <Dialog
            open={this.state.categoryOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{
                background: "#FDBA27",
                color: "white",
                minWidth: "30rem",
              }}
            >
              <label className="modalheading">{"Settings"}</label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Add {this.state.categoryName}</b>
              </DialogContentText>
              <TextField
                margin="dense"
                id="category"
                name="category"
                label="Category"
                type="text"
                onChange={this.handleChange}
                fullWidth
                required
              />
              <div style={{ marginTop: "3%" }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <>
                      <Button
                        variant="outlined"
                        onClick={this.handlePickerClick}
                        startIcon={<PaletteIcon />}
                      >
                        Pick Color
                      </Button>
                      {this.state.displayColorPicker ? (
                        <div style={styles.popover}>
                          <div
                            style={styles.cover}
                            onClick={this.handlePickerClose}
                          />
                          <SketchPicker
                            color={this.state.color}
                            onChange={this.handleColorChange}
                          />
                        </div>
                      ) : null}
                    </>
                  </Grid>
                  <Grid item xs={2} />

                  <Grid item xs={4}>
                    <div style={styles.color} />
                  </Grid>
                </Grid>
              </div>
              <div className="row" style={{ marginTop: "2%" }}>
                <div className="col-sm-6">
                  <label>Upload Image</label>
                </div>
                <div className="col-sm-6">
                  <ImageUploader
                    id="file"
                    placeholder="Upload Image"
                    type="file"
                    onChange={this.handleImageSelect}
                  />
                </div>
              </div>
              {this.state.loading ? (
                <Grid container spacing={2}>
                  <Grid item xs={3} />

                  <Grid item xs={9} style={{ color: "red" }}>
                    Uploading Image! Please wait.....
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.handleLocationSubmit}
                color="primary"
                disabled={this.state.image === ""}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          
 {/* ==========================Delete Location Dialog=============================== */}
<div>
<Dialog
  open={this.state.confirmOpen}
  onClose={()=>this.setState({confirmOpen:false})}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
<DialogTitle
              id="form-dialog-title"
              style={{ background: "#FDBA27", color: "white" }}
            >
              <label className="modalheading">{"Delete Location"}</label>
            </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
    Are you sure you want to delete Location ?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={()=>this.setState({confirmOpen:false})} color="primary">
      Cancel
    </Button>
    <Button onClick={this.deleteLocation} color="primary" autoFocus>
      Confirm
    </Button>
  </DialogActions>
</Dialog>
</div>
        </div>
      </div>
    );
  }
}

export default Settings;
