import React from 'react'
import {withRouter } from 'react-router';
import {
    Link,
    Typography,
    Breadcrumbs,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Tooltip,
  } from "@material-ui/core";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import app from '../../../firebase/firebaseConfig';
import {
    AddOutlined as AddOutlinedIcon,
    VisibilityOutlined as VisibilityOutlinedIcon,
    Close as CloseIcon,
    NotificationsNoneOutlined as NotificationIcon
} from '@material-ui/icons';


import ShowDetails from './components/showDetails';
import AddNewAd from './components/addNewAd';
import Notification from './components/notification';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    dataGrid: {
      minHeight: '132px',
  
      '& .MuiDataGrid-dataContainer, & .MuiDataGrid-viewport': {
        minWidth: 'auto!important'
      },
  
      '& .MuiDataGrid-viewport': {
        width: 'fit-content',
        maxWidth: 'none!important',
        minWidth: '100%!important'
      },
  
      '& .MuiDataGrid-viewport, & .MuiDataGrid-renderingZone, & .MuiDataGrid-row': {
        maxHeight: 'fit-content!important',
      },
  
      '& .MuiDataGrid-renderingZone': {
        transform: 'none!important',
        marginRight: '-16px'
      },
  
      '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-cell': {
        textOverflow: 'unset',
        whiteSpace: 'normal',
        lineHeight: '1.2!important',
        maxHeight: 'fit-content!important',
        minHeight: 'auto!important',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
  
        '& > div': {
          maxHeight: 'inherit',
          width: '100%',
          whiteSpace: 'initial',
          lineHeight: '1'
        }
      },
  
      '& .MuiDataGrid-columnHeader > div': {
        height: '100%'
      },
  
      '& .MuiDataGrid-columnHeaderWrapper': {
        maxHeight: 'none!important',
        flex: '1 0 auto',
      },
  
      '& .MuiDataGrid-row .MuiDataGrid-columnsContainer': {
        maxHeight: 'none!important'
      },
  
      '& .MuiDataGrid-cell': {
        overflowWrap: 'anywhere',
        padding: '0',
  
        '&--textRight div': {
          textAlign: 'right',
          justifyContent: 'flex-end'
        },
  
        '&:last-of-type > div': {
          paddingRight: theme.spacing(3)
        },
  
        '& > div': {
          padding: '0.75em',
          display: 'flex',
          alignSelf: 'stretch',
          alignItems: 'center'
        }
      }
    }
  });

class AdsManagement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo : [],
            open : false,
            component : "",
            label : "",
            loading : true,
            markersData : {},
            views : {}
        }
        this.columns = [
            {
                field: 'id',
                hide: true,
            },
            {
                field: "name",
                headerName: "Name",
                flex: 0.15,
                editable: false,
            },
            {
                field: "Userid",
                headerName: "UserId",
                flex : 0.22,
                editable: false,
            },
            {
                field: "phone",
                headerName: "Phone",
                flex: 0.12,
                editable: false,
            },
            {
                field: "credit",
                headerName: "Credit",
                flex : 0.08,
                editable: false,
            },
            {
                field: "listingCount",
                headerName: "Total Listings",
                flex: 0.12,
                editable: false,
            },
            {
                field: "storyViews",
                headerName: "Total Views",
                resizable : true,
                flex: 0.35,
                valueFormatter : (params) => this.handleStoryView(params, true),
                renderCell: (params) => this.handleStoryView(params),
                editable: false,
            },
            {
                field: "adsManagement",
                headerName: "Actions",
                flex: 0.1,
                renderCell: (params) => this.renderEditButton(params),
                editable: false,
            }
        ];
    }

    handleShowViews = () => {
        app.database().ref(`Analytic/bs_views`).once("value", (snapshot) => {
            if (snapshot.exists()) {
                this.setState({views: snapshot.val()})
            } 
        }).then(() => {
            this.getUserInfo()
        })
    }

    CustomToolbar = () => {
        return (
          <GridToolbarContainer style={{justifyContent:'flex-end'}}>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
    }

    updateMarkersData = (p_id, a_id, flag) => {
        let data = this.state.markersData
        data["markers"][p_id]["listingStory"][a_id].flag = flag
        this.setState({markersData : data})
    }

    handleStoryView = (params, formatter) => {
        let listingJson = {}
        let listings = params.row.listings
        Object.keys(listings).map((k,i) => {
            let listing = k
            let storys = this.state.views[listing]
            
            storys && Object.keys(storys).map((ck,ci) => {
                if (storys[ck].count) {
                    listingJson[listing] = {}
                    listingJson[listing][ck] = storys[ck].count
                } else {
                    listingJson[listing] = {}
                    listingJson[listing][ck] = Object.keys(storys[ck]).length
                }
            })
        })

        
        if (formatter) {
            let text = ""
            Object.keys(listingJson).map((k,i) => {
                text = text + "\n" + `Location : ${params.row.listings[k].address} \n`
                text = text + "\n" + `Story Id                           Views \n`
                Object.keys(listingJson[k]).map((c_k, c_i) => {
                    text = text + '\n' + `${c_k}        ${listingJson[k][c_k]} \n`
                })
            })
            return text

        } else {
            return (
                <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                    {Object.keys(listingJson).map((k,i) => 
                        <div>
                            <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                <Typography variant='subtitle2' style={{width:145}}>Location : </Typography>
                                <Typography>{params.row.listings[k].address}</Typography>
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                <Typography variant='subtitle2'>Story ID</Typography>
                                <Typography variant='subtitle2'>Views</Typography>
                            </div>
                            {Object.keys(listingJson[k]).map((c_k, c_i) => 
                                <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                    <Typography variant='subtitle2'>{c_k}</Typography>
                                    <Typography>{listingJson[k][c_k]}</Typography>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        }
        
    }

    getUserInfo = () => {
        app.database().ref('users').on('value', (snapShot) => {
            if (snapShot.exists()) {
                this.setState({userData: snapShot.val()}, () => {
                    let userInfo = []
                    for (const [key, value] of Object.entries(snapShot.val())) {
                        let count = 0;
                        if (value.listings !== undefined) {
                            for (const objValue of Object.entries(value.listings)) {
                                if (objValue[1].listingStory !== undefined) {
                                    let count1 = Object.keys(objValue[1].listingStory).length;
                                    count = count + count1;
                                }
                            }
                        }
                        userInfo.push({
                            id:key,
                            Userid: key,
                            Credit: value?.credit,
                            name: value.name ? value.name : '',
                            credit: value.credit ? value.credit : '',
                            phone: value.phone ? value.phone : '',
                            listings: value.listings ? value.listings : '',
                            listingCount: count,
                            data : this.state.markersData
                        })
                    }
                    let filterInfo = userInfo.filter(f => f.name !== '' && f.credit !== '')
                    this.setState({userInfo: [...filterInfo]})
                    setTimeout(() => {this.setState({loading: false})}, 100)
                })
            } else {
                this.setState({loading : false})
            }
        })
    }

    handleShowDetails = (params) => {
        this.setState({open : true, component : <ShowDetails data={params} app={app} markersData={this.state.markersData} updateData ={this.updateMarkersData}/>, label : "Details"})
    }

    handleClose = () => {
        this.setState({open : false})
    }

    handleNewAd = (params) => {
        this.setState({open : true, component : <AddNewAd data={params} app={app}/>, label : "Add New Ad"})
    }

    handleNotification = (params) => {
        this.setState({open : true, component : <Notification data={params} app={app} />, label : "Send Notification"})
    }

    renderEditButton = (params) => {
        return (
            <div style={{display:'flex', gap:10}}>
                <Tooltip title="Show Details" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleShowDetails(params)} aria-label="Show Details" ><VisibilityOutlinedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip>
                <Tooltip title="Add new Ad" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleNewAd(params)} ><AddOutlinedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip> 
                <Tooltip title="Send Notification" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleNotification(params)} ><NotificationIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip>  
            </div>
        )
    }

    fetchMarkersData = () => {
        app.firestore().collection('madMap').doc('markers').get().then((snapShot) => {
            this.setState({markersData : snapShot.data()})
        }).then(() => {
            this.handleShowViews()
        })
    }

    componentDidMount = () => {
        this.fetchMarkersData()
    }

    render = () => {
        const { classes } = this.props;
        return (
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link color="inherit" href="#/ ">
                        <Typography variant="caption">Home</Typography>
                    </Link>
                    <Typography variant="caption">Ads Management</Typography>
                </Breadcrumbs>
                
                <div style={{display: "flex", flex: 1}}>
                    <div style={{display: "flex", flex: 1}}>
                        <div style={{width: "100%", marginTop: '3%'}}>
                            <DataGrid
                                components={{
                                    Toolbar: this.CustomToolbar,
                                }}
                                
                                className={classes.dataGrid}
                                style={{backgroundColor:'white'}}
                                rows={this.state.userInfo}
                                columns={this.columns}
                                rowHeight={0}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                checkboxSelection
                                disableSelectionOnClick
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                {/* Open Dialog Window */}
                <Dialog
                    // style={{display:'flex', flex:1}}
                    open={this.state.open}
                    maxWidth = {"md"}
                    onClose={this.handleClose}
                    fullWidth={true}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        disableTypography
                        style={{ background: "#FDBA27", color: "white", display:'flex', justifyContent:'space-between', alignItems:'center' }}
                        >
                        <label style={{color:'white'}}>{this.state.label}</label>
                        <IconButton size="small" onClick={this.handleClose} > <CloseIcon style={{color:'white'}} /></IconButton>
                    </DialogTitle>
                    <DialogContent style={{minHeight:300}}>
                        {this.state.component}
                    </DialogContent>

                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(AdsManagement))