import './App.css';
import React from 'react'
import rtl from 'jss-rtl';
import { withRouter, BrowserRouter } from 'react-router-dom'
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { createNewTheme } from './theme';
import Routes from './routes'
import { create } from 'jss';
import useSettings from './hooks/useSettings';


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { settings } = useSettings();
  const theme = createNewTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });


  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);
