import React from 'react'   
import { Route, Switch } from 'react-router-dom';
import Dashboard from "./components/dashboard/dashboard";
import Login from './components/auth/Login'
import Signup from './components/auth/signup'
import  Home from './components/dashboard/home'
import PrivacyPolicy from "./components/dashboard/privacyPolicy";
import EndUserLicenseAgreement from "./components/dashboard/eulaPage";
import TermsOfUse from "./components/dashboard/termsOfUse";
import ViewListings from "./components/dashboard/viewListings";


export default function Routes() {
    return (
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route exact path='/privacy-policy'>
                <PrivacyPolicy />
            </Route>
            <Route exact path='/terms-of-use'>
                <TermsOfUse />
            </Route>
            <Route exact path='/admin'>
                <Dashboard />
            </Route>
            <Route exact path='/login'>
                <Login />
            </Route>
            <Route exact path='/sign'>
                <Signup />
            </Route>
            <Route path='#/viewListings/:id'>
                <ViewListings />
            </Route>
            <Route exact path='/end-user-license-agreement'>
                <EndUserLicenseAgreement />
            </Route>
        </Switch>
    )
}


