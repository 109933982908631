import { TextField, Typography, Card, Button, Snackbar } from '@material-ui/core'
import React from 'react'
import ImageUploader from "react-images-upload";
import Preview from './notificationPreview';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';


var config = {
    method: 'post',
    url: 'https://fcm.googleapis.com/fcm/send',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': 'key=AAAAz0CoBD4:APA91bHI21MbkQhdAsFZZ_IkaSEwOWz_4eXelTHvU-GMUay8re4lHvjeGLIjx00hvRCo07sxp3mHJKUiXOAxGnENxsfyzLwcVHxsOV9pKxqDtOWXhjfOEcHnXZBAT1NtlklrpFnEvzDS'
    },
};

var SendButtonStyle = {
    color : "#FDBA27",
    borderColor : "#FDBA27",
    borderRadius : 20,
    height : 35,
    alignSelf : 'center'

}

var SendButtonHoverStyle = {
    color : "white",
    backgroundColor : "#FDBA27",
    borderColor : "#FDBA27",
    borderRadius : 20,
    height : 35,
    alignSelf : 'center'

}

class Notification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            title : "",
            text : "",
            img : "",
            fcm : "",
            AlertOpen : false,
            AlertMsg : "",
            AlertSeverity : "",
            buttonStyle : SendButtonStyle,
        }
    }

    componentDidMount = () => {
        this.handleFetchFcmToken(this.props.data.id)
    }

    handleNotificationSend = async() => {
        if (this.state.fcm) {
            if (this.state.text !== "" || this.state.text !== undefined) {
                this.setState({AlertOpen: true, AlertMsg : "Sending Notification .....", AlertSeverity : "info"})
                let imgFormat = this.state.img.type.split('/')[1]
                let imgName = `madmap-noti-photo-${Math.floor(Date.now() /1000)}.${imgFormat}`
                let imgRef = this.props.app.storage().ref().child(`notification/${imgName}`)
                        //
                this.setState({AlertOpen: true, AlertMsg : "Uploading image ....", AlertSeverity : "info"})
                var imgUrl = ""
                await imgRef.put(this.state.img).then(async (snapshot) => {
                    let downloadUrl = await imgRef.getDownloadURL()
                    imgUrl = downloadUrl
                    this.setState({AlertOpen: true, AlertMsg : "Photo Uploaded ...", AlertSeverity : "info"})
                })
                let data = JSON.stringify({
                    to : this.state.fcm,
                    notification : {
                        title : this.state.title,
                        body : this.state.text,
                        image : imgUrl
                    }                    
                })
                config.data = data
                axios(config)
                .then((response) => {
                    if (response.data.success > 0) {
                        this.setState({AlertOpen: true, AlertMsg : "Notification Sent Successfully", AlertSeverity : "success"})
                    } else {
                        this.setState({AlertOpen: true, AlertMsg : "Sending Failed", AlertSeverity : "error"})
                    }
                })
                .catch((error) => {
                    this.setState({AlertOpen: true, AlertMsg : error, AlertSeverity : "error"})
                });
            }else {
                this.setState({AlertOpen: true, AlertMsg : "Notification Text is required field!", AlertSeverity : "warning"})
            }
            
        } else {
            this.setState({AlertOpen: true, AlertMsg : "No Valid FCM Token Found", AlertSeverity : "error"})
        }
        
    }

    handleFetchFcmToken = (user_id) => {
        this.props.app.database().ref(`users/${user_id}/fcm`).once("value", (snapshot) => {
            if (snapshot.exists()) this.setState({fcm : snapshot.val()})
        })
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    onDrop = (picture) => {
        this.setState({img : picture[0]})
    }

    render = () => {
        return (
            <div style={{display:'flex', flex:1, gap:10}}>
                <div style={{display:'flex', flex:1, flexDirection:'column', gap:20}}>
                    <div>
                        <Typography variant="subtitle1">Notification title</Typography>
                        <TextField variant="outlined" style={{width:"100%"}} placeholder="Enter optional title" onChange={(e) => this.setState( {title: e.target.value})} />
                    </div>
                    <div >
                        <Typography variant="subtitle1">Notification text</Typography>
                        <TextField variant="outlined" style={{width:"100%"}} placeholder="Enter notification text" onChange={(e) => this.setState({text: e.target.value})} />
                    </div>
                    <div style={{flex:1, justifyContent:'space-between', display:'flex'}}>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <Typography>Notification Image (optional)</Typography>
                            <ImageUploader
                                    withIcon={false}
                                    buttonText='Upload Image (optional)'
                                    onChange={(event) => this.onDrop(event, 'photo')}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                    singleImage
                                    maxFileSize={5242880}
                                />
                        </div>
                        <Button variant="outlined" onMouseEnter={() => this.setState({buttonStyle: SendButtonHoverStyle})} 
                            onClick={this.handleNotificationSend}
                            onMouseLeave={() => this.setState({buttonStyle : SendButtonStyle})}
                            style={this.state.buttonStyle}  endIcon={<SendOutlinedIcon />}> Send</Button>
                    </div>
                    
                    
                </div>
                <Card elevation={1} style={{display:'flex', flex:1, flexDirection:'column', padding:20, gap:20}}>
                    <Typography style={{fontSize:20}} variant="subtitle1">Device Preview</Typography>
                    <Typography style={{fontSize:18}} variant="caption">
                        This preview provides a general idea of how your message will appear on a mobile device. Actual message rendering will vary depending on the device. Test with a real device for actual results.
                    </Typography>
                    <Preview data={this.state} />
                </Card>
                <Snackbar open={this.state.AlertOpen} autoHideDuration={2000} onClose={() => this.setState({AlertOpen : false, AlertMsg:"", AlertSeverity:''})}>
                    <Alert onClose={() => this.setState({AlertOpen : false, AlertMsg:"", AlertSeverity:''})} severity={this.state.AlertSeverity}>
                        {this.state.AlertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default Notification