import { Card, Link, Typography, Breadcrumbs } from '@material-ui/core'
import React from 'react'


class Users extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount = () => {

    }

    render () {
        return (
            <div style={ {display:'flex', flexDirection:'column', flex:1}}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link color="inherit" href="/ ">
                        <Typography variant='caption'>Home</Typography>
                    </Link>
                    <Typography variant='caption'>User Management</Typography>
                </Breadcrumbs>
                <div
                    title="User Management"
                    style={{display:'flex', flex:1}}
                    >
                    <Card style={{display:'flex', flex:1}}>
                        HI
                    </Card>
                </div>
            </div>
        )
    }
}

export default Users