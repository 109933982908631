import React from "react";

const TermsOfUse = () => {
  return (
    <div className="mainContainer">
      <div className="off_canvars_overlay"></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="offcanvas_menu_wrapper">
                <div className="canvas_close">
                  <a href="javascript:void(0)">
                    <i className="fal fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/madmaps-apps"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/_madmaps" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <a className="page-scroll" href="/#home" target="_self">
                        Home
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a className="page-scroll" href="/#intro" target="_self">
                        Features
                      </a>
                    </li>
                    <li className="menu-item-has-children">
                      <a
                        className="page-scroll"
                        href="/#preview"
                        target="_self"
                      >
                        Preview
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a
                        className="page-scroll"
                        name="faqs"
                        href="/#faqs"
                        target="_self"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="terms-of-use">
                        Terms of use
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link page-scroll"
                        href="end-user-license-agreement"
                      >
                        End user license agreement
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="home" className="header-area header-v1-area">
        <div className="header-nav fc-header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="/">
                      <img
                        src="assets2/images/madmapswebsite/Logo-new.png?v=1"
                        alt=""
                      />
                    </a>
                    <span className="side-menu__toggler canvas_open">
                      <i className="fa fa-bars"></i>
                    </span>

                    <div
                      className="collapse navbar-collapse sub-menu-bar main-nav__main-navigation"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto main-nav__navigation-box">
                        <li className="nav-item active">
                          <a className="nav-link page-scroll" href="/#home">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#intro">
                            Features
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#preview">
                            Preview
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#faqs">
                            FAQs
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="privacy-policy"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="terms-of-use"
                          >
                            Terms of use
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="end-user-license-agreement"
                          >
                            End user license agreement
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container privacy-policy">
        <div className="row pb-2">
          <div className="col-md-12">
            <h1 className="text-left my-5">Terms of Use</h1>
            <p>Effective Date: 14th April, 2022</p>
            <br />
            <p>
              The website located at www.madmaps.in and the mobile application
              ‘MadMaps’ available for download on the Android stores (together,
              the “Platform”) are owned and operated by MadMaps Travel Tech
              Private Limited (“Company”). Through the Platform, the Company
              provides the Platform’s users, This is a single integrated
              platform (i) where local businesses upload their latest ad stories
              or latest inventories to get discovered, and (ii) And where
              relevant local content will show information and ideas in short
              snackable formats for anyone wanting to explore. Along with
              reviews and comments by travellers. The term ‘Company’, ‘we’ or
              ‘us’ refers to MadMaps Travel Tech Private Limited. The term ‘you’
              refers to the users of the Platform, and members of the Program.
              The Program is provided by the Company subject to the terms and
              conditions set forth in these Terms and Conditions (“Terms”). By
              using / registering on the application, you agree to comply and be
              bound by these Terms, and to be bound by and comply with the Terms
              (as defined herein). The Company reserves the right to modify
              these Terms from time to time. All modifications will be posted on
              the Platform and unless otherwise specified, will become effective
              upon such posting to the Platform. Please read the Terms regularly
              to remain informed of any change.
            </p>
            <br />
            <h4>I. ACCEPTANCE OF TERMS</h4>
            <p>
              Thank you for using MadMaps. These Terms of Service (the "Terms")
              are intended to make you aware of your legal rights and
              responsibilities with respect to your access to and use of the
              MadMaps website at www.madmaps.in (the "Site") and related mobile
              or software applications ("MadMaps Platform") including but not
              limited to delivery of information via the website whether
              existing now or in the future that link to the Terms
              (collectively, the "Services").
            </p>
            <br />
            <p>
              These Terms are effective for all existing and future MadMaps
              customers, including but without limitation to users having access
              to ‘business page' to manage their claimed business listings.
            </p>
            <br />
            <p>
              Please read these Terms carefully. By accessing or using the
              MadMaps Platform, you are agreeing to these Terms and concluding a
              legally binding contract with MadMaps Travel Tech Private Limited
              and/or its affiliates. You may not use the Services if you do not
              accept the Terms or are unable to be bound by the Terms. Your use
              of the MadMaps Platform is at your own risk, including the risk
              that you might be exposed to content that is objectionable, or
              otherwise inappropriate.
            </p>
            <br />
            <h5>
              In order to use the Services, you must first agree to the Terms.
              You can accept the Terms by:
            </h5>
            <br />
            <ul>
              <li>
                Clicking to accept or agree to the Terms, where it is made
                available to you by MadMaps in the user interface for any
                particular Service; or
              </li>
              <li>
                Using the Services: You understand and agree that MadMaps
                will treat your use of the Services as acceptance of the Terms
                from that point onwards.
              </li>
            </ul>
            <br />
            <h4>II. DEFINITIONS </h4>
            <br />
            <p>
              In these Terms, unless the context otherwise requires, the
              following expressions shall have the following meanings:
            </p>
            <ul>
              <li>
                “Wallet Money” shall mean money credited in the application to
                create advertisements by the Service Provider(s). Every
                advertisement upload is for Rs. 30 for easy templates or Rs. 50
                for using the editor.
              </li>
              <li>
                “Service Provider(s)” shall mean the restaurants, bars, hotels,
                shops, operators of attractions, activity and experience
                providers, and other persons and entities, who upload their
                advertisements on the Platform.
              </li>
              <li>
                “Travelers Attractions” are monuments, temples and places of
                interest and sightseeing.
              </li>
              <li>
                “Mad Library” is a collection of photo stories, video stories
                and Mad Collection (third party content) for users to find the
                informative content in one place.
              </li>
              <li>
                “My Ads” are ads uploaded by Service Providers on the MadMaps
                application. “Third Party Content” is content which features the
                best of what is going on in a place by third parties.
              </li>
              {/* <li>
                8. “Third Party Content” is content which features the best of
                what is going on in a place by third parties.
              </li>
              <li>
                9. “Registration” shall mean the agreement between the Company
                and the Service Provider wherein the Service Provider will
                register on the application and upload the pictures/videos etc.
                about their business.
              </li>
              <li>
                10. Eligibility For using the application only, a natural person
                who has reached the age of majority i.e., the age of 18 years
                (Eighteen years) shall be eligible. Also, the eligibility is
                affected by the ability of the person to enter into a contract.
                The person seeking renewal of membership should not be barred by
                law to enter into a contract. By using the services of the
                MadMaps the person warrants that they are at least of the age of
                18 years and are competent to understand the terms, conditions,
                obligations, affirmations, representations, and warranties set
                forth in these Terms. The person using the services of the
                Website shall comply with all applicable laws and regulations
                and ensure that the rights of any third party are not violated.
              </li> */}
            </ul>
            <br />
            <h4>Eligibility</h4>
            <br />
            <p>
              IOnly natural persons will be eligible to purchase/renew
              membership in the Program. Any individual seeking to
              purchase/renew membership in the Program must be above 18
              (eighteen) years of age or above the age of majority as per the
              law applicable to such individuals and must not be disqualified
              from contracting by any law to which the individual is subject. By
              placing an offer for the purchase of membership in the Program and
              availing of the Benefits under the Program, you represent and
              warrant that you are of legal age and not prohibited by law from
              purchasing or availing the Benefits extended under the Program.
            </p>
            <br />
            <h4>Jurisdiction</h4>
            <br />
            <p>
              We are headquartered in Gurugram, Haryana, India and the Services
              are intended for mainly consumer services/user & service providers
              in India . By viewing any Content or otherwise using our services
              in India or anywhere around the world, you consent to the transfer
              of information throughout India to the extent applicable, and the
              collection, storage, and processing of information under Indian
              laws.
              <ol start="1">
                <li>
                  You hereby represent and warrant that you are at least
                  eighteen (18) years of age or above and are fully able and
                  competent to understand and agree the terms, conditions,
                  obligations, affirmations, representations, and warranties set
                  forth in these Terms.{" "}
                </li>
                <li>
                  Compliance with Laws. You are in compliance with all laws and
                  regulations in the country in which you live when you access
                  and use the Services. You agree to use the Services only in
                  compliance with these Terms and applicable law, and in a
                  manner that does not violate our legal rights or those of any
                  third party(ies).
                </li>
              </ol>
            </p>
            <br />
            <h4>Additional Assistance</h4>
            <br />
            <p>
              If you have any questions or concerns about the Terms, call us at
              9818045110; or write to us at: support@madmaps.in
            </p>
            <br />
            <p>
              Please be ensured that any personal information that you provide
              in communication to the above email, telephone number and postal
              address will not be used to send your personal material.
            </p>
            <br />
            <h4>Changes to the terms</h4>
            <br />
            <p>
              MadMaps may vary or amend or change or update these Terms, from
              time to time entirely at its own discretion. You shall be
              responsible for checking these Terms from time to time and ensure
              continued compliance with these Terms. Your use of MadMaps
              Platform after any such amendment or change in the Terms shall be
              deemed as your express acceptance to such amended/changed terms
              and you also agree to be bound by such changed/amended Terms
            </p>
            <ol start="1">
              <li>
                By using MadMaps Services you agree to the following
                disclaimers:
                <ul>
                  <li>
                    The Content on these Services is for informational purposes
                    only. MadMaps disclaims any liability for any information
                    that may have become outdated since the last time the
                    particular piece of information was updated. MadMaps
                    reserves the right to make changes and corrections to any
                    part of the Content on these Services at any time without
                    prior notice.
                  </li>
                  <li>
                    Unless stated otherwise, all pictures and information
                    contained on these Services are believed to be owned by or
                    licensed to MadMaps. Please email a takedown request (by
                    using the "support@madmaps.in” email provided on the
                    application/ home page) to the webmaster if you are the
                    copyright owner of any Content on these Services and you
                    think the use of the above material violates your copyright
                    in any way. Please indicate the exact URL of the webpage in
                    your request. All images shown here have been digitized by
                    MadMaps. No other party is authorized to reproduce or
                    republish these digital versions in any format whatsoever
                    without the prior written permission of MadMaps.{" "}
                  </li>
                  <li>
                    Any certification, licenses or permits ("Certification") or
                    information in regard to such Certification that may be
                    displayed on the Service Provider's advertisement listing’
                    on the MadMaps Platform is for informational purposes only.
                    Such Certification is displayed by MadMaps on an 'as
                    available' basis that is provided to MadMaps by the Service
                    Partner(s). MadMaps does not make any warranties about the
                    validity, authenticity, reliability and accuracy of such
                    Certification or any information displayed in this regard.
                    Any reliance by a Customer upon the Certification or
                    information thereto shall be strictly at such Customer's own
                    risk and MadMaps in no manner shall assume any liability
                    whatsoever for any losses quality of the goods, the prices
                    listed in menus or the availability of all menu.
                  </li>
                </ul>
              </li>
              <li>
                MadMaps does not guarantee the items at any Restaurant/Merchant.
                or damages in connection with the use of this information or for
                any inaccuracy, invalidity or discrepancy in the Certification
                or non-compliance of any applicable local laws or regulations by
                the Restaurant partner/Merchant.
              </li>
              <li>
                MadMaps reserves the right to charge a subscription and/or
                membership and/or a convenience fee from a Customer, by giving
                reasonable prior notice, in respect of any product, service or
                any other aspect of the MadMaps Platform anytime in future.
              </li>
              <li>
                MadMaps may from time to time introduce referral and/or
                incentive based programs for its Customers (Program). These
                Program(s) may be governed by their respective terms and
                conditions. By participating in the Program, Customers are bound
                by the Program terms and conditions as well as the MadMaps
                Platform terms. Further, MadMaps reserves the right to terminate
                / suspend the Customer's account and/or credits / money
                earned/accumulated and/or participation of the Customer on the
                platform/ application if MadMaps determines in its sole
                discretion that the Customer has violated the rules of the
                platform/ application and/or has been involved in activities
                that are in contravention of the platform/ application terms
                and/or MadMaps Platform terms or has engaged in activities which
                are fraudulent / unlawful in nature. Furthermore, MadMaps
                reserves the right to modify, cancel and discontinue its Program
                without notice to the Customer.
              </li>
            </ol>
            <br />
            <br />
            <h4>
              By using this application, you agree to such terms and conditions,
              as well as these Terms and our Privacy Policy (available here link
              to the privacy policy)
            </h4>
            <br />
            <br />
            <h4>Use of services by you or Customer </h4>
            <br />
            <br />
            <ol start="1">
              <li>
                <h4>MadMaps Customer Account Access</h4>
                <p>
                  a. You must create an account in order to use some of the
                  features offered by the Services, including without limitation
                  to 'claim your business listing' on the Services. Use of any
                  personal information you provide to us during the account
                  creation process is governed by our Privacy Policy. You must
                  keep your password confidential and you are solely responsible
                  for maintaining the confidentiality and security of your
                  account, all changes and updates submitted through your
                  account, and all activities that occur in connection with your
                  account.{" "}
                </p>
                <br />
                <br />
                <p>
                  b. You may also be able to register to use the Services by
                  logging into your account with your credentials from certain
                  third party social networking sites (e.g., Facebook). You
                  confirm that you are the owner of any such social media
                  account and that you are entitled to disclose your social
                  media login information to us. You authorize us to collect
                  your authentication information, and other information that
                  may be available on or through your social media account
                  consistent with your applicable settings and instructions.
                </p>
                <br />
                <br />
                <p>
                  c. In creating an account and/or claiming your business'
                  listing, you represent to us that all information provided to
                  us in such a process is true, accurate and correct, and that
                  you will update your information as and when necessary in
                  order to keep it accurate. If you are creating an account or
                  claiming a business listing, then you represent to us that you
                  are the owner or authorized agent of such business. You may
                  not impersonate someone else, create or use an account for
                  anyone other than yourself, provide an email address other
                  than your own, create multiple accounts or business listings
                  except as otherwise authorized by us, or provide or use <br />
                  false information to obtain access to a business' listing on
                  the Services that you are not legally entitled to claim. You
                  acknowledge that any false claiming of a business listing may
                  cause MadMaps or third parties to incur substantial economic
                  damages and losses for which you may be held liable and
                  accountable.
                </p>
                <br />
                <br />
                <p>
                  d. You are also responsible for all activities that occur in
                  your account. You agree to notify us immediately of any
                  unauthorized use of your account in order to enable us to take
                  necessary corrective action. You also agree that you will not
                  allow any third party to use your MadMaps account for any
                  purpose and that you will be liable for such unauthorized
                  access.
                </p>
                <br />
                <br />
                <p>
                  e. By creating an account, you agree to receive certain
                  communications in connection with MadMaps Platform or
                  Services. For example, you might receive comments from other
                  Customers or other Customers may follow the activity to do on
                  your account. You can opt-out or manage your preferences
                  regarding non-essential communications through account
                  settings.
                </p>
                <br />
                <p>
                  By using the Madmaps’ website and application service you are
                  agreeing to be bound by the following terms and conditions
                  ("Terms of Use").
                </p>
                <br />
                <br />
              </li>
            </ol>
            <h4>User Restrictions</h4>
            <br />
            <p>
              We want people to use MadMaps to share content that is important
              to them, but not at the expense of the safety and well-being of
              others or the integrity of the community. You therefore agree not
              to engage in the conduct described below (or to facilitate or
              support others in doing so):
            </p>
            <p>You may not use our Application to do or share anything:</p>
            <ul>
              <li>
                That breaches these Terms and other terms and policies that
                apply to your use of MadMaps.
              </li>
              <li>
                That is unlawful, misleading, discriminatory or fraudulent.
              </li>
              <li>
                That infringes or breaches someone else’s rights, including
                their intellectual property rights.
              </li>
            </ul>
            <br />
            <p>
              You may not upload viruses or malicious code, or do anything that
              could disable, overburden or impair the proper working or
              appearance of our Application. You may not access or collect data
              from our Application using automated means (without prior
              permission) or attempt to access data that you do not have
              permission to access.
            </p>
            <br />
            <br />
            <h4>Basic Terms</h4>
            <br />
            <ol start="1">
              <li>You must be 18 years or older to use this application.</li>
              <li>
                You may not post nude, partially nude, or sexually suggestive
                photos.
              </li>
              <li>
                You are responsible for any activity that occurs under your
                screen name.
              </li>
              <li>You are responsible for keeping your password secure.</li>
              <li>
                You must not abuse, harass, threaten, impersonate or intimidate
                other users.{" "}
              </li>
              <li>
                You may not use our services for any illegal or unauthorized
                purpose. All users agree to comply with all local laws regarding
                online conduct and acceptable content.
              </li>
              <li>
                You are solely responsible for your conduct and any data, text,
                information, screen names, graphics, photos, profiles, audio and
                video clips, links ("Content") that you submit, post, and
                display on the MadMaps service.
              </li>
              <li>
                We can remove any content or information you share on the
                service if we believe that it violates these Terms of Use, our
                policy, or we are permitted or required to do so by law.
              </li>
              <li>
                You must not modify, adapt or hack MadMaps or modify another
                website so as to falsely imply that it is associated with
                MadMaps.
              </li>
              <li>
                You must not access MadMaps’ private API by any other means
                other than the MadMaps application itself.
              </li>
              <li>
                You must not crawl, scrape, or otherwise cache any content from
                MadMaps including but not limited to user profiles and photos.
              </li>
              <li>
                You must not create or submit unwanted email or comments to any
                MadMaps members ("Spam").
              </li>
              <li>
                You must not use web URLs in your name without prior written
                consent from MadMaps, inc.
              </li>
              <li>
                You must not transmit any worms or viruses or any code of a
                destructive nature.
              </li>
              <li>
                You must not, in the use of MadMaps, violate any laws in your
                jurisdiction (including but not limited to copyright laws).
              </li>
              <li>
                Violation of any of these agreements will result in the
                termination of your MadMaps account. While MadMaps prohibits
                such conduct and content on its application and website, you
                understand and agree that MadMaps cannot be responsible for the
                Content posted on its application and you nonetheless may be
                exposed to such materials and that you use the MadMaps service
                at your own risk.
              </li>
            </ol>
            <br />
            <br />
            <h4>General Conditions</h4>
            <br />
            <ol start="1">
              <li>
                We reserve the right to modify or terminate the MadMaps service
                for any reason, without notice at any time.
              </li>
              <li>
                We reserve the right to alter these Terms of Use at any time. If
                the alterations constitute a material change to the Terms of
                Use, we will notify you via our application or website according
                to the preference expressed on your account. What constitutes a
                "material change" will be determined at our sole discretion, in
                good faith and using common sense and reasonable judgement.
              </li>
              <li>
                We reserve the right to force forfeiture of any username that
                becomes inactive, violates trademark, or may mislead other
                users.
              </li>
              <li>
                We may, but have no obligation to, remove Content and accounts
                containing Content that we determine in our sole discretion are
                unlawful, offensive, threatening, libelous, defamatory, obscene
                or otherwise objectionable or violates any party's intellectual
                property or these Terms of Use.
              </li>
              <li>
                We reserve the right to reclaim usernames on behalf of
                businesses or individuals that hold legal claim or trademark on
                those usernames.
              </li>
              <li>
                We are not responsible for your experience at the Service
                providers location and the Service Providers experience with the
                client/ patron.{" "}
              </li>
              <li>
                The service provider reserves the Right of admissions, rules &
                guidance at the venue{" "}
              </li>
              <li>
                The money deposited in the application cannot be redeemed for
                cash with MadMaps or the Service Provider.{" "}
              </li>
            </ol>
            <br />
            <br />
            <h4>Proprietary Rights in Content on MadMaps</h4>
            <br />
            <ol start="1">
              <li>
                MadMaps does NOT claim ANY ownership rights in the text, files,
                images, photos, video, sounds, musical works, works of
                authorship, applications, or any other materials (collectively,
                "Content") that you post on or through the MadMaps Services. By
                displaying or publishing ("posting") any Content on or through
                the MadMaps Services, you hereby grant to MadMaps a
                non-exclusive, fully paid and royalty-free, worldwide, limited
                license to use, modify, delete from, add to, publicly perform,
                publicly display, reproduce and translate such Content,
                including without limitation distributing part or all of the
                Site in any media formats through any media channels, except
                Content not shared publicly ("private") will not be distributed
                outside the MadMaps Services.
              </li>
              <li>
                Some of the MadMaps Services are supported by advertising
                revenue and may display advertisements and promotions, and you
                hereby agree that MadMaps may place such advertising and
                promotions on the MadMaps Services or on, about, or in
                conjunction with your Content. The manner, mode and extent of
                such advertising and promotions are subject to change without
                specific notice to you.
              </li>
              <li>
                You represent and warrant that: (i) you own the Content posted
                by you on or through the MadMaps Services or otherwise have the
                right to grant the license set forth in this section, (ii) the
                posting and use of your Content on or through the MadMaps
                Services does not violate the privacy rights, publicity rights,
                copyrights, contract rights, intellectual property rights or any
                other rights of any person, and (iii) the posting of your
                Content on the Site does not result in a breach of contract
                between you and a third party. You agree to pay for all
                royalties, fees, and any other monies owing any person by reason
                of Content you post on or through the MadMaps Services.
              </li>
              <li>
                The MadMaps Services contain Content of MadMaps ("MadMaps
                Content"). MadMaps Content is protected by copyright, trademark,
                patent, trade secret and other laws, and MadMaps owns and
                retains all rights in the MadMaps Content and the MadMaps
                Services. MadMaps hereby grants you a limited, revocable, non
                sublicensable license to reproduce and display the MadMaps
                Content (excluding any software code) solely for your personal
                use in connection with viewing the Site and using the MadMaps
                Services.
              </li>
              <li>
                The MadMaps Services contain Content of Users and other MadMaps
                licensors. Except as provided within this Agreement, you may not
                copy, modify, translate, publish, broadcast, transmit,
                distribute, perform, display, or sell any Content appearing on
                or through the MadMaps Services.
              </li>
              <li>
                MadMaps performs technical functions necessary to offer the
                MadMaps Services, including but not limited to transcoding
                and/or reformatting Content to allow its use throughout the
                MadMaps Services.
              </li>
              <li>
                Although the Site and other MadMaps Services are normally
                available, there will be occasions when the Site or other
                MadMaps Services will be interrupted for scheduled maintenance
                or upgrades, for emergency repairs, or due to failure of
                telecommunications links and equipment that are beyond the
                control of MadMaps. Also, although MadMaps will normally only
                delete Content that violates this Agreement, MadMaps reserves
                the right to delete any Content for any reason, without prior
                notice. Deleted content may be stored by MadMaps in order to
                comply with certain legal obligations and is not retrievable
                without a valid court order. Consequently, MadMaps encourages
                you to maintain your own backup of your Content. In other words,
                MadMaps is not a backup service. MadMaps will not be liable to
                you for any modification, suspension, or discontinuation of the
                MadMaps Services, or the loss of any Content.
              </li>
              <li>
                To the maximum extent permitted by law, we will not be liable
                for any damages of any kind arising from the use of this
                application including.
              </li>
            </ol>
            <br />
            <br />
            <h4>Taxes</h4>
            <br />
            <p>
              You shall be responsible for payment of all fees/costs/charges
              associated with the purchase of services from/through us or the
              merchant and you agree to bear any and all applicable taxes
              including but not limited to VAT/CST, service tax, GST, duties and
              cesses etc.
            </p>
            <br />
            <br />
            <h4>Events beyond our reasonable control</h4>
            <br />
            <p>
              We will not be held responsible for any delay or failure to comply
              with our obligations under these conditions if the delay or
              failure arises from any cause which is beyond our reasonable
              control. This condition does not affect your statutory rights.
            </p>
            <br />
            <br />
            <h4>Waiver</h4>
            <br />
            <p>
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>

      <section className="footer-area">
        <div className="container">
          <div className="footer-content">
            <div className="footer-about mt-30">
              <a href="#">
                <img
                  src="assets2/images/madmapswebsite/Logo-new.png?v=1.1"
                  alt=""
                />
              </a>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/madmaps-apps"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/_madmaps" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
              <p className="pl-md-5 ml-md-5">@MadMaps-2021</p>
            </div>
          </div>
        </div>
      </section>

      <a className="back-to-top">
        <i className="fal fa-angle-up"></i>
      </a>
    </div>
  );
};
export default TermsOfUse;
