import React from "react";

const EndUserLicenseAgreement = () => {
  return (
    <div className="mainContainer">
      <div className="off_canvars_overlay"></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="offcanvas_menu_wrapper">
                <div className="canvas_close">
                  <a href="javascript:void(0)">
                    <i className="fal fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/madmaps-apps"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/_madmaps" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <a className="page-scroll" href="/#home" target="_self">
                        Home
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a className="page-scroll" href="/#intro" target="_self">
                        Features
                      </a>
                    </li>
                    <li className="menu-item-has-children">
                      <a
                        className="page-scroll"
                        href="/#preview"
                        target="_self"
                      >
                        Preview
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a
                        className="page-scroll"
                        name="faqs"
                        href="/#faqs"
                        target="_self"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="terms-of-use">
                        Terms of use
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link page-scroll"
                        href="end-user-license-agreement"
                      >
                        End user license agreement
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="home" className="header-area header-v1-area">
        <div className="header-nav fc-header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="/">
                      <img
                        src="assets2/images/madmapswebsite/Logo-new.png?v=1"
                        alt=""
                      />
                    </a>
                    <span className="side-menu__toggler canvas_open">
                      <i className="fa fa-bars"></i>
                    </span>

                    <div
                      className="collapse navbar-collapse sub-menu-bar main-nav__main-navigation"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto main-nav__navigation-box">
                        <li className="nav-item active">
                          <a className="nav-link page-scroll" href="/#home">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#intro">
                            Features
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#preview">
                            Preview
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#faqs">
                            FAQs
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="privacy-policy"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="terms-of-use"
                          >
                            Terms of use
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="end-user-license-agreement"
                          >
                            End user license agreement
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container privacy-policy">
        <div className="row pb-2">
          <div className="col-md-12">
            <h1 className="text-left my-5">End User License Agreement</h1>
            <p>Effective Date: 15th April, 2022</p>
            <br />
            <p>
              These terms of use (Terms) constitute a legally binding agreement
              between you and the company regarding your use of the MadMaps
              mobile application offered by the company. By accessing the
              application or service and / or by clicking "I agree", you agree
              to be bound by these terms. “User” or “You” means any person who
              will access or avail this application of the company for the
              purpose of hosting, publishing, sharing, transacting, displaying
              or uploading information or views and includes other persons
              jointly participating in using the application of the company. You
              hereby represent and warrant to the company that you are at least
              eighteen (18) years of age or above and are capable of entering,
              performing and adhering to these Terms and that you agree to be
              bound by the following terms and conditions. While individuals
              under the age of 18 may utilize MadMaps services, they shall do so
              only with the involvement & guidance of their parents and / or
              legal guardians, under such Parent / Legal guardian’s registered
              account. You agree to register prior to uploading any content and
              / or comment and any other use or services of this application and
              provide your details including but not limited to complete name,
              age, email address, residential address, contact number. MadMaps
              may add to or change or update these Terms of Use, from time to
              time entirely at its own discretion. You are responsible for
              checking these Terms of Use periodically to remain in compliance
              with these terms. Your use of the application after any amendment
              to the Terms of Use shall constitute your acceptance of these
              terms and you also agree to be bound by any such changes /
              revisions.
            </p>
            <br />
            <h4>Copyright and Trademarks</h4>
            <br />
            <p>
              Unless otherwise stated, copyright and all intellectual property
              rights in all material presented on the mobile app (including but
              not limited to text, audio, video or graphical images), trademarks
              and logos appearing on this application are the property of
              MadMaps and are protected under applicable Indian laws. You agree
              not to use any framing techniques to enclose any trademark or logo
              or other proprietary information of MadMaps; or remove, conceal or
              obliterate any copyright or other proprietary notice or any
              credit-line or date-line on other mark or source identifier
              included on the application / service, including without
              limitation, the size, color, location or style of all proprietary
              marks. Any infringement shall be vigorously defended and pursued
              to the fullest extent permitted by law.
            </p>
            <br />
            <h4>Sharing of Personal Information</h4>
            <br />
            <p>
              MadMaps is designed to help discover cafes, restaurants, bakeries,
              bars, shops, hotels, events and services through ad stories that
              they decide to upload on the map. In order to do this, MadMaps
              requires the use of such information including, but not limited
              to, your email, password and or other information that was used
              while Signing Up with the services. For certain services such as
              email, ‘my places’, ‘mad library’, signing up by the visitor is
              required. To sign up for these services you have to open an
              account by completing the signing up process (i.e. by providing us
              with current, complete and accurate information as prompted by the
              applicable registration form). You will also choose a password.
              You are entirely responsible for maintaining the confidentiality
              of your password and account. By Signing Up, you agree to the
              following terms in addition to any other specific terms which
              shall be posted at an appropriate location of the application.
              Each sign up is for a single individual user only. To access these
              services, you will be asked to enter your individual email address
              and Password, as chosen by you during your signing up. Therefore,
              we do not permit any of the following: -
            </p>
            <br />
            <ul>
              <li>Any other person sharing your account and password;</li>
              <li>
                Any part of the application being cached in proxy servers and
                accessed by individuals who have not registered with MadMaps as
                users of the application; or
              </li>
              <li>
                Access through a single account and password being made
                available to multiple users on a network.
              </li>
            </ul>
            <br />
            <p>
              If MadMaps reasonably believes that an account and password is
              being used / misused in any manner, MadMaps shall reserve the
              right to cancel access rights immediately without notice, and
              block access to all users from that IP address. Furthermore, you
              are entirely responsible for any and all activities that occur
              under your account. You agree to notify MadMaps immediately of any
              unauthorized use of your account or any other breach of security.
              MadMaps will not be liable for any loss that you may incur as a
              result of someone else using your password or account. However,
              you could be held liable for losses incurred by MadMaps or another
              party due to someone else using your account or password.
            </p>
            <br />
            <h4>Use of Profile Data</h4>
            <br />
            <p>
              We may use the personal information to provide the services you
              request. However, to the extent we use your personal information
              to market to you, we will also provide you the ability to opt-out
              of such uses. The personal information shall be used by us to
              resolve disputes; troubleshoot problems; help/promote a safe
              service; measure consumer interest in our products and services,
              inform you about online and offline offers, products, services,
              and updates; customize your experience; detect and protect us
              against error, fraud and other criminal activity; enforce our
              terms and conditions; and as otherwise described to you at the
              time of collection. In our efforts to continually improve our
              products and services, we collect and analyze demographic and
              profile data about our users' activity on our mobile application.
            </p>
            <br />
            <p>
              We may occasionally ask you to engage in factual or interest-based
              surveys. These surveys may ask you for more information around
              your interest and demographic information. The data collected
              through these surveys is solely to enhance your experience in the
              usage of the mobile application, providing you with content that
              we think you might be interested in, and to display content
              according to your preferences. It is however clarified that
              filling in of such surveys is optional.
            </p>
            <br />
            <h4>Location Sharing</h4>
            <br />
            <p>
              MadMaps is about recommending cafes, restaurants, bakeries, bars,
              shops, hotels, events, services and travelers attraction places
              around you and your city and in order to do that we would need to
              know your location. Whenever you open and interact with the
              MadMaps app on your mobile, we use the location information from
              your mobile device (latitude and longitude) to customize the
              experience around your location. Your location information is
              NEVER shared with others. MadMaps mobile app also allows you to
              select a locality of your choice or let the app determine your
              current location by tapping on your location name on the Location
              Change screen. It allows us to tell you where the recommendations
              are based on the location that is determined by the device or your
              selection.
            </p>
            <br />
            <h4>No unlawful or prohibited use</h4>
            <br />
            <p>
              As a condition of your use of the services, you will not use the
              Services for any purpose that is unlawful or prohibited by these
              terms, conditions, and notices. You may not use the services in
              any manner that could damage, disable, overburden, or impair any
              of our servers, or the network(s) connected to any MadMaps server,
              or interfere with any other party's use and enjoyment of any
              Services. You may not attempt to gain unauthorized access to any
              Services, other accounts, computer systems or to any of the
              services, through hacking, password mining or any other means. You
              may not obtain or attempt to obtain any materials or information
              through any means not intentionally made available through the
              Services.
            </p>
            <br />
            <h4>Material Posted/transmitted at MadMaps</h4>
            <br />
            <p>
              All information, data, text, software, music, sound, photographs,
              graphics, video, messages or other materials ("Content"), whether
              publicly or privately transmitted / posted, is the sole
              responsibility of the person from whom such content originated
              (the Originator). By posting any material which contain images,
              photographs, pictures or that are otherwise graphical in whole or
              in part ("Images"), you warrant and represent that (a) you are the
              copyright owner of such Images, or that the copyright owner of
              such Images has granted you permission to use such Images or any
              content and/or images contained in such Images consistent with the
              manner and purpose of your use and as otherwise permitted by these
              Terms of Use and the Services, (b) you have the rights necessary
              to grant the licenses and sublicenses described in these Terms of
              Use, and (c) that each person depicted in such Images, if any, has
              provided consent to the use of the Images as set forth in these
              Terms of Use, including, by way of limitation, the distribution,
              public display and reproduction of such Images
            </p>
            <br />
            <p>
              You represent that you have valid rights and title in any and all
              Content/Images that you submit on the application, that you have
              not infringed on any IPR (Intellectual property rights) belonging
              to any party and further that you will indemnify MadMaps for all
              claims arising out of any content that you post on the
              application.You represent that you have valid rights and title in
              any and all Content/Images that you submit on the application,
              that you have not infringed on any IPR (Intellectual property
              rights) belonging to any party and further that you will indemnify
              MadMaps for all claims arising out of any content that you post on
              the application.
            </p>
            <br />
            <p>
              MadMaps accepts no responsibility for the said Content. However,
              you understand that all Content posted by you becomes the property
              of MadMaps and you agree to grant/assign to MadMaps, a
              non-exclusive, royalty free, perpetual, irrevocable and
              sublicensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, perform and
              display such Content (in whole or part) worldwide and/or to
              incorporate it in other works in any form, media, or technology
              now known or later developed throughout the world.
            </p>
            <br />
            <h4>Exchange of Information & other Community Services</h4>
            <br />
            <p>
              You represent that you are a responsible adult (18 years or above)
              and/or otherwise capable of entering into and performing legal
              agreements, and that you agree to be bound by the following terms
              and conditions. While individuals under the age of 18 years may
              utilize the service, they may do so only with the involvement of a
              parent or legal guardian, under such person’s account and
              otherwise subject to these Terms. In certain jurisdictions, the
              age of majority may be older than 18, in which case you must
              satisfy that age in order to become a member/utilize the service
              and you shall be solely responsible for all Content that you
              upload, post or otherwise transmit.
            </p>
            <br />
            <p>
              MadMaps endeavors to provide a mobile app / web platform for
              various community interactions for persons to interact and
              exchange views with each other. The content posted on such
              services is by the general public therefore the accuracy,
              integrity or quality of such content cannot be guaranteed. You
              understand that by using such services, you may be exposed to
              objectionable matter. By using the facility of review/rating and
              other community services you agree not to upload, post, or
              otherwise transmit any matter or views, which are, defamatory,
              abusive, pervasive, obscene, invasive of another's privacy,
              hateful, or racially, ethnically or otherwise objectionable. You
              may also not indulge in cyber stalking, become or create a
              nuisance for any visitor / user of the application.
            </p>
            <br />
            <p>
              You may not send, submit, post, or otherwise transmit, material or
              messages that contain software virus, or any other files that are
              designed to interrupt, destroy, and negatively affect in any
              manner whatsoever, any electronic equipment in connection with the
              use of this application, or other user's ability to engage in real
              time exchanges.
            </p>
            <br />
            <h4>Prohibited Activities</h4>
            <br />
            <p>
              The User shall not host, display, upload, modify, publish,
              transmit, store, update or share any information that,
            </p>
            <ul>
              <li>
                belongs to another person and to which the user does not have
                any right;
              </li>
              <li>
                is defamatory, obscene, pornographic, paedophilic, invasive of
                another’s privacy, including bodily privacy, insulting or
                harassing on the basis of gender, libellous, racially or
                ethnically objectionable, relating or encouraging money
                laundering or gambling, or otherwise inconsistent with or
                contrary to the laws in force;
              </li>
              <li>is harmful to children;</li>
              <li>
                infringes any patent, trademark, copyright or other proprietary
                rights,
              </li>
              <li>violates any law for the time being in force;</li>
              <li>
                deceives or misleads the addressee about the origin of the
                message or knowingly and intentionally communicates any
                information which is patently false or misleading in nature but
                may reasonably be perceived as a fact;
              </li>
              <li>impersonates another person;</li>
              <li>
                threatens the unity, integrity, defense, security or sovereignty
                of India, friendly relations with foreign States, or public
                order, or causes incitement to the commission of any cognisable
                offense or prevents investigation of any offense or is insulting
                other nation;
              </li>
              <li>
                contains software virus or any other computer code, file or
                program designed to interrupt, destroy or limit the
                functionality of any computer resource;
              </li>
              <li>
                is patently false and untrue, and is written or published in any
                form, with the intent to mislead or harass a person, entity or
                agency for financial gain or to cause any injury to any person.
              </li>
            </ul>
            <br />
            <p>
              Any Content and or comment uploaded by you, shall be subject to
              relevant Indian laws and may be disabled, or and may be subject to
              investigation under appropriate laws. Furthermore, if you are
              found to be in non-compliance with the laws and regulations, these
              terms, or the privacy policy of the application, the Company shall
              have the right to immediately terminate/block your access and
              usage of the application and the Company shall have the right to
              immediately remove any non-compliant Content and or comment,
              uploaded by you and shall further have the right to take recourse
              to such remedies as would be available to the Company under the
              applicable laws.
            </p>
            <br />
            <h4>Limitation of Liability</h4>
            <br />
            <p>
              Any transactions relating to booking or reservations of goods or
              services not directly offered by MadMaps are to be settled
              inter-se between the parties to such transaction and all
              warranties express or implied of any kind, regarding any matter
              pertaining thereto, including without limitation the implied
              warranties of merchantability, fitness for a particular purpose,
              and non-infringement are disclaimed by MadMaps. MadMaps merely
              endeavors to provide a platform where you and other parties may
              interact, exchange information or carry out a reservation on such
              a basis that are mutually agreed between you and the other party.
              MadMaps shall not be liable for any loss that you may incur, while
              making reservations or bookings of the third party. Under no
              circumstances shall MadMaps be held responsible or liable, in any
              way, for any content which in Legal opinion is derogatory,
              threatening, defamatory, obscene or offensive or offends public
              sensibilities or morals and shall also not assume liability for
              any errors or omissions in any content, or for any loss or damage
              of any kind incurred as a result of the use of any content posted
              or uploaded on the application, or any infringement of another's
              rights, including intellectual property rights. You specifically
              agree that MadMaps is not responsible for any content sent using
              and/or included in MadMaps’s service by any third party.
            </p>
            <br />
            <h4>Termination of Account</h4>
            <br />
            <p>
              MadMaps reserves its right to (a) refuse Service, (b) restrict,
              suspend, or terminate your account; (c) terminate this Agreement;
              (d) terminate or suspend your access to the application; (e)
              refuse, move or remove for any reason any Content / Image that you
              submit on or through the Services; (f) refuse, move, or remove any
              Content / Image that is available on or through the Services; (g)
              establish general practices and limits concerning use of the
              Services at any time and, (h) remove or edit contents or cancel
              orders (entered by you) in its sole discretion with or without
              cause, and with or without any prior notice for any violation of
              the Terms of Use.
            </p>
            <br />
            <h4>User Conduct and Obligations</h4>
            <br />
            <p>
              You hereby agree and assure MadMaps that the Services shall be
              used for lawful purposes only and that you will not violate laws,
              regulations, ordinances or other such requirements of any
              applicable Central, State or local government or any other
              international laws. You further concur that you will not, through
              the application:
            </p>
            <ul>
              <li>
                post, distribute, or otherwise make available or transmit any
                software or other computer files that contain a virus trojan
                horse, time bombs, bots, botnets, malicious content, content
                theft, data manipulation, threats or any other harmful programs
                or elements or component;
              </li>
              <li>
                delete from the application any legal notices, disclaimers, or
                proprietary notices such as copyright or trademark symbols, or
                modify any logos that you do not own or have express permission
                to modify;
              </li>
              <li>
                not use Service in any manner that could damage, disable,
                overburden, or impair and not to undertake any action which is
                harmful or potentially harmful to any MadMaps's server, or the
                network(s), computer systems / resource connected to any
                MadMaps's server, or interfere with any other party's use and
                enjoyment of the Service;
              </li>
              <li>
                obtain or attempt to obtain any materials or information through
                any means not intentionally made available through the Service;
              </li>
              <li>engage in any activity that causes / may harm minors; or</li>
              <li>perform any activity which is likely to cause such harm;</li>
              <li>
                impersonate any person or entity, including, but not limited to,
                MadMaps’s official, forum leader, guide or host, or falsely
                state or otherwise misrepresent your affiliation with a person
                or entity;
              </li>
              <li>
                take any action which encourages or consists of any threat of
                harm of any kind to any person or property;
              </li>
              <li>
                carry out any "denial of service" (DoS, DDoS) or any other
                harmful attacks on application or internet service or;
              </li>
              <li>use the Service for illegal purposes;</li>
              <li>
                disrupt, place unreasonable burdens or excessive loads on,
                interfere with or attempt to make or attempt any unauthorized
                access to any MadMaps’s mobile app.
              </li>
              <li>
                transmit through the Mobile app, any unlawful, harassing,
                libelous, abusive, threatening, harmful, vulgar, obscene,
                libelous, hateful, or racially, ethnically or otherwise
                objectionable material of any kind or nature. This includes
                text, graphics, video, programs or audio, etc.;
              </li>
              <li>
                collect or attempt to collect personally identifiable
                information of any person or entity without their express
                written consent and you shall maintain records of any such
                written consent throughout the terms of this agreement and for a
                period of 2 years thereafter; engage in antisocial, disruptive,
                or destructive acts, including "flaming," "spamming,"
                "flooding," "trolling," and "griefing" as those terms are
                commonly understood and used on the Internet
              </li>
              <li>
                forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any content transmitted through the
                application.
              </li>
              <li>
                upload, post, email, transmit or otherwise make available any
                unsolicited or unauthorized advertising, promotional materials,
                "junk mail", "spam", "chain letters", "pyramid schemes",
                duplicative messages or any other form of solicitation.
              </li>
              <li>
                encumber or suffer to exist any lien or security interest on the
                subject matter of this Agreement; or make any representation or
                warranty on behalf of MADMAPS.
              </li>
              <li>
                You agree not to post, distribute, transmit or otherwise make
                available any data, text, message, computer file, or other
                material that infringes and/or violates any right of a third
                party or any domestic or international law, rule, or regulation,
                including but not limited to:
              </li>
              <li>
                infringement of any copyright, trademark, patent, trade secret,
                or other proprietary rights of any third party, including, but
                not limited to, the unauthorized copying of copyrighted
                material, the digitization and distribution of photographs from
                magazines, books, or other copyrighted sources, and the
                unauthorized transmittal of copyrighted software;
              </li>
              <li>
                right of privacy (specifically, you must not distribute another
                person's personal information of any kind without their express
                permission) or publicity;
              </li>
              <li>
                any confidentiality obligation. Unless otherwise permitted, you
                will: (i) display the Service on your application or Products in
                the exact form received by you, and not modify or edit any of
                the foregoing without MadMaps's prior written consent; (ii)
                ensure that the fundamental meaning of the Service is not
                changed or distorted; (iii) comply with all applicable laws and
                all limitations and restrictions (if any) placed by MadMaps on
                the use, display or distribution of any Service and (iv) not
                archive any of the Service for access by users at any future
                date after the Service has been removed from your Website or
                product accordingly. You acknowledge that the service must link
                and redirect to the appropriate MadMaps's web page when a user
                clicks on the Service. You shall not display the Service in such
                a manner that does not allow for successful linking and
                redirection to, and delivery of, MadMaps’ Web page, nor may you
                frame any MadMaps's Web page.
              </li>
            </ul>
            <br />
            <h4>No Controlling Spam Policy or Unsolicited Emails</h4>
            <br />
            <p>
              You will not use any communication tool or other means available
              on the application to transmit, directly or indirectly, any
              unsolicited bulk communications (including emails and instant
              messages). MadMaps will not sell, lease or rent its email
              subscriber lists to third parties. You may not harvest information
              about users of MadMaps for the purpose of sending or to facilitate
              the sending of unsolicited bulk communications. We may terminate
              your access or use of the service immediately, with or without any
              notice, and take any other legal action if you, or anyone using
              your access details to the Mobile app, violates these terms. We
              may adopt any technical remedy (including any filtering technology
              or other measures) to prevent unsolicited bulk communications from
              entering, utilizing or remaining within our computer or
              communication networks. Such filtering technology or other
              measures may block, either temporarily or permanently, some email
              sent to you through the MadMaps's application.
            </p>
            <br />
            <h4>Consent Template Registration</h4>
            <br />
            <p>
              Once you are a registered customer of MadMaps, your email address
              and other personally identifiable information may be used to send
              product updates and marketing communications. To opt-out from such
              messages, click on the Unsubscribe link on any such email you
              receive from us. We may still continue to use your email address
              to notify you of any major administrative or operational updates
              for customer services purposes, account information, billing, etc.
            </p>
            <br />
            <h4>Disclaimer of Warranties and Liability</h4>
            <br />
            <p>
              MadMaps disclaims all warranties, express or implied, statutory or
              otherwise, as to the Services provided, including without
              limitation, the implied warranties of merchantability, fitness for
              a particular purpose, workmanlike effort, title and
              non-infringement are disclaimed and excluded.
            </p>
            <br />
            <p>
              MadMaps shall not be liable, at any time for any, direct,
              indirect, punitive, incidental, special, consequential, damages
              (including, without limitation, damages for loss of business
              projects, damage to your computer system or damages for loss of
              profits, damages for loss of use, data or profits, arising out of
              or in any way connected with the use or performance of the MadMaps
              services, with the delay or inability to use the MadMaps's related
              services, the provision of or failure to provide services, or for
              any information, software, products, services and related graphics
              obtained through the MadMaps services) arising in contract, tort
              or otherwise from the use of or inability to use the application,
              or any of its contents, or from any act or omissions a result of
              using the application or any such contents or for any failure of
              performance, error, omission, interruption, deletion, defect,
              delay in operation or transmission, computer virus, communications
              line failure, theft or destruction or unauthorized access to,
              alteration of, or use of information contained on the application.
              No representations, warranties or guarantees whatsoever are made
              by MadMaps as to the (accuracy, adequacy, reliability,
              completeness, suitability or applicability of the information to a
              particular situation; (b) that the service will be uninterrupted,
              timely, secure, or error-free; (c) the quality of any products,
              services, content, information, or other material purchased or
              obtained from the mobile app will meet your expectations or
              requirements; or (d) any errors in the mobile app will be
              corrected.
            </p>
            <br />
            <h4>Material Submitted by Users</h4>
            <br />
            <p>
              Certain elements of the application will contain material
              submitted by users. MadMaps accepts no responsibility for the
              content, accuracy, conformity to applicable laws of such material.
              You will indemnify and hold harmless MadMaps against all
              third-party claims, demands and actions brought against MadMaps
              which arises from or relates to the material submitted by you.
            </p>
            <br />
            <h4>Advertising Material</h4>
            <br />
            <p>
              MadMaps may send information and offer products and services to
              you from time to time. For further details relating to our policy
              relating to such offers please refer to our privacy statement.
              Notwithstanding the foregoing, MadMaps reserves the right to
              disclose any information in response to / that it is required to
              be shared, disclosed or made available to any governmental,
              administrative, regulatory or judicial authority under any law or
              regulation applicable to MadMaps.
            </p>
            <br />
            <h4>Data Protection</h4>
            <br />
            <p>
              Part of the application contains advertising information or
              promotion material or other material submitted to MadMaps by third
              parties. Responsibility for ensuring that material submitted for
              inclusion on Mobile app complies with applicable International and
              National law is exclusively on the party providing the
              information/material. Your correspondence or business dealings
              with, or participation in promotions of, advertisers other than
              MadMaps found on or through Mobile app, including payment and
              delivery of related goods or services, and any other terms,
              conditions, warranties or representations associated with such
              dealings, are solely between You and such advertiser. MadMaps will
              not be responsible or liable for any claim, error, omission,
              inaccuracy in advertising material or any loss or damage of any
              sort incurred as the result of any such dealings or as the result
              of the presence of such non-MadMaps advertisers on the Mobile app.
              MadMaps reserves the right to omit, suspend or change the position
              of any advertising material submitted for insertion. Acceptance of
              advertisements on the Mobile apps will be subject to these terms
              and conditions.
            </p>
            <br />
            <h4>Relationship</h4>
            <br />
            <p>
              None of the provisions of the User Agreement shall be deemed to
              constitute a partnership or agency between you and MadMaps and you
              shall have no authority to bind MadMaps in any manner, whatsoever.
            </p>
            <br />
            <h4>Indian Law & Dispute Resolution</h4>
            <br />
            <p>
              The Agreement shall be governed by the Laws of India. We are
              headquartered in Gurugram, Haryana, India and the Services are
              intended for mainly consumer services/user & service providers in
              India and across the globe. The Courts of Gurgaon, Haryana shall
              have exclusive jurisdiction for dispute resolution in all matters
              and all redressals shall be sought before Courts in Gurgaon,
              Haryana.
            </p>
            <br />
            <h4>Entire Agreement</h4>
            <br />
            <p>
              These Terms of Service constitute the entire agreement between the
              parties with respect to the subject matter hereof and supersedes
              and replaces all prior or contemporaneous understandings or
              agreements, written or oral, regarding such subject matter.
            </p>
            <br />
            <h4>Right to remove inappropriate messages</h4>
            <br />
            <p>
              We reserve the right to remove any messages we deem to be
              inappropriate, i.e, racist, sexist or threatening. Messages using
              inappropriate language will also be removed. We do not wish to be
              censors, but our forums must remain civilized and respect the
              rights of others.
            </p>
            <br />
            <h4>Limited time to bring your claim</h4>
            <br />
            <p>
              You and MadMaps agree that any cause of action arising out of or
              related to the MadMaps’s mobile app, only, must commence within
              one (1) year after the cause of action accrues otherwise, such
              cause of action will be permanently barred.
            </p>
            <br />
            <h4>Acceptance Policy of Privacy</h4>
            <br />
            <p>
              By using MadMaps’s services, you signify your acceptance of this
              Privacy Statement. If you do not agree or are not comfortable with
              any policy described in this Privacy statement, your only remedy
              is to discontinue use of MadMaps mobile app. We reserve the right
              to modify this Privacy Statement at any time.
            </p>
            <br />
            <h4>
              Notice of Copyright Infringement and Grievance Redressal
              Mechanism:
            </h4>
            <br />
            <p>
              MadMaps is not liable for any infringement of copyright arising
              out of materials posted on or transmitted through the mobile app,
              or items advertised on the mobile app, by end users or any other
              third parties.
            </p>
            <br />
            <p>
              <strong>Redressal Mechanism:</strong> Any complaints, abuse or concerns with
              regards to content and or comment or breach of these terms shall
              be immediately informed to the designated Grievance Officer as
              mentioned below via in writing or through email signed with the
              electronic signature to
            </p><br/>
            <p>Xxxxxxxxxxx</p>
            <p>Xxxxxxxxxxx</p>
            <p>Xxxxxxxxxxx</p>
            <p>Xxxxxxxxxxx</p><br/>
            
            <br />
            <h4>Copyright Notice</h4>
            <br />
            <p>
            xxxxx. All Rights Reserved.This disclaimer/ terms of service notification is subject to change without notice.
            </p><br/>
          </div>
        </div>
      </div>

      <section className="footer-area">
        <div className="container">
          <div className="footer-content">
            <div className="footer-about mt-30">
              <a href="#">
                <img
                  src="assets2/images/madmapswebsite/Logo-new.png?v=1.1"
                  alt=""
                />
              </a>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/madmaps-apps"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/_madmaps" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
              <p className="pl-md-5 ml-md-5">@MadMaps-2021</p>
            </div>
          </div>
        </div>
      </section>

      <a className="back-to-top">
        <i className="fal fa-angle-up"></i>
      </a>
    </div>
  );
};
export default EndUserLicenseAgreement;
