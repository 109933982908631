import React from "react";
const PrivacyPolicy = () => {
  return (
    <div className="mainContainer">
      <div className="off_canvars_overlay"></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="offcanvas_menu_wrapper">
                <div className="canvas_close">
                  <a href="javascript:void(0)">
                    <i className="fal fa-times"></i>
                  </a>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a
                        href="https://www.linkedin.com/company/madmaps-apps"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/_madmaps" target="_blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li className="menu-item-has-children active">
                      <a className="page-scroll" href="/#home" target="_self">
                        Home
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a className="page-scroll" href="/#intro" target="_self">
                        Features
                      </a>
                    </li>
                    <li className="menu-item-has-children">
                      <a
                        className="page-scroll"
                        href="/#preview"
                        target="_self"
                      >
                        Preview
                      </a>
                    </li>
                    <li className="menu-item-has-children ">
                      <a
                        className="page-scroll"
                        name="faqs"
                        href="/#faqs"
                        target="_self"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link page-scroll" href="terms-of-use">
                        Terms of use
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link page-scroll"
                        href="end-user-license-agreement"
                      >
                        End user license agreement
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="home" className="header-area header-v1-area">
        <div className="header-nav fc-header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="/">
                      <img
                        src="assets2/images/madmapswebsite/Logo-new.png?v=1"
                        alt=""
                      />
                    </a>
                    <span className="side-menu__toggler canvas_open">
                      <i className="fa fa-bars"></i>
                    </span>

                    <div
                      className="collapse navbar-collapse sub-menu-bar main-nav__main-navigation"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto main-nav__navigation-box">
                        <li className="nav-item active">
                          <a className="nav-link page-scroll" href="/#home">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#intro">
                            Features
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#preview">
                            Preview
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link page-scroll" href="/#faqs">
                            FAQs
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="privacy-policy"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="terms-of-use"
                          >
                            Terms of use
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link page-scroll"
                            href="end-user-license-agreement"
                          >
                            End user license agreement
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container privacy-policy">
        <div className="row pb-2">
          <div className="col-md-12">
            <h1 className="text-left my-5">Privacy Policy</h1>
            <p>Effective Date: 14th April, 2022</p>
            <ol start="1">
              <li>
                Introduction
                <br />
              </li>
              <li>
                Information We Collect and How We Use It
                <br />
              </li>
              <li>
                Sharing Information with Third Parties
                <br />
              </li>
              <li>
                Rights and Information of Subscribers
                <br />
              </li>
              <li>
                Advertising
                <br />
              </li>
              <li>
                Opt-Out Options
                <br />
              </li>
              <li>
                Storing Your Personal Data
                <br />
              </li>
              <li>
                Automatic Transactional Information
                <br />
              </li>
              <li>
                Payment Gateway
                <br />
              </li>
              <li>
                Accounts, Deposits & Disbursements
                <br />
              </li>
              <li>
                Wallet Points
                <br />
              </li>
              <li>
                Data Retention
                <br />
              </li>
              <li>
                Applicable law
                <br />
              </li>
              <li>
                Updates / Changes
                <br />
              </li>
              <li>
                Policy Related To Minors
                <br />
              </li>
              <li>
                Contacting us
                <br />
              </li>
              <li>
                General Disclaimer
                <br />
                <br />
              </li>
              <li>
                <strong>Introduction</strong>
                <br />
                <strong>MadMaps Travel Tech Private Limited</strong> and its
                sub-sites, platforms, applications, platforms and other
                platforms (collectively referred to as ”MadMaps” “MadMaps
                Application” “MadMaps App” “Application” “Sites”) for delivery
                of information, advertisements, products, offerings and content
                via any mobile or internet connected device or otherwise
                (collectively the "Services"). This Policy forms part and parcel
                of the Terms of Use and other terms on the Site (“Terms of
                Use”). Capitalized terms which have been used here but are
                undefined shall have the same meaning as attributed to them in
                the Terms of Use.
                <br />
                <br />
                This Privacy Policy is applicable to persons who access, browse
                or use the Services (“User”). For the purpose of this Policy,
                wherever the context so requires "you" or "your" shall mean User
                and the term "we", "us", "our" shall mean Company.&nbsp;
                <br />
                <br />
                As we update, improve and expand the Services, this Policy may
                change, so please refer back to it periodically. By accessing
                and using the Sites or otherwise using the Services, you consent
                to collection, storage, and use of the personal information you
                provide (including any changes thereto as provided by you) for
                any of the Services that we provide.
                <br />
                <br />
                MadMaps respects the privacy of the Users of the Services and is
                committed to protect it in all respects. The information about
                the User as collected by MadMaps is: (a) information supplied by
                Users and (b) information automatically tracked while navigation
                (c) information collected from any other source (collectively
                referred to as “Information”). <br />
                <br />
                This policy DOES NOT apply to information that you provide to,
                or that is collected by, any third-party, such as restaurants at
                which you make reservations and / or pay through Company's
                Services and social networks that you use in connection with its
                Services. Company encourages you to consult directly with such
                third-parties about their privacy practices. <br />
                <br />
                Please read this policy carefully to understand MadMaps's
                policies and practices regarding your information and how
                Company will treat it. By accessing or using its Services and/or
                registering for an account with Company, you agree to this
                privacy policy and you are consenting to Company's collection,
                use, disclosure, retention, and protection of your personal
                information as described here. If you do not provide the
                information Company requires, Company may not be able to provide
                all of its Services to you.
                <br />
                <br />
              </li>
              <li>
                <strong>Information We Collect and How We Use It</strong>
                <br />
                We may collect, transmit, and store information about you in
                connection with your use of the Service, including any
                information you send to or through the Service. We use that
                information to provide the Service’s functionality, fulfil your
                requests, improve the Service’s quality, engage in research and
                analysis relating to the Service, personalize your experience,
                track usage of the Service, display third party businesses that
                are listed on the Service, display relevant advertisement,
                market the Service, provide customer support, message you, back
                up our systems, allow for disaster recovery, enhance the
                security of the Service, and comply with legal obligations. Even
                when we do not retain such information, it still must be
                transmitted to our servers initially and stored long enough to
                process.
                <br />
                <br />
                Please also note:
                <br />
                <ol type="i">
                  <li>
                    <strong>Account Information</strong>: When you create an
                    account on our application/ website, we store and use the
                    information you provide during that process, such as the
                    first and last name you enter, email address, pin code,
                    physical address, IP address as well as data pertaining to
                    Live Location when App (Application) is accessed/ opened or
                    using the service that is dependent on it as well as any
                    other information you may provide during the account
                    creation process, such as a gender, phone number, or birth
                    date. We may publicly display the first name and last
                    initial that you provide, as well as any photo or other
                    content you submit through the account creation process, as
                    part of your account profile. We also store and use any
                    preferences you provide to personalize your user experience,
                    including dining and activity preferences. You can later
                    modify some of the account information you provide through
                    your account settings. If you believe that someone has
                    created an unauthorized account using your personal
                    information, you can request its removal by flagging it. For
                    the said purposes you may request its removal by sending an
                    email to{" "}
                    <a href="mailto:support@madmaps.in">support@madmaps.in</a>
                    .&nbsp;We will thereafter verify the facts based on the
                    details submitted by you and upon verification we would
                    proceed further for the removal of any of such content.
                  </li>
                  <br />
                  <li>
                    <strong>Service Data</strong>: When you visit our
                    application / websites and use our Services, we receive
                    information either voluntarily entered by you or others, or
                    passively logged by the application/website itself. We may
                    also collect usage and log data about how the Services are
                    accessed and used, including information about the device
                    you are using to access the Services, IP addresses, location
                    information, language settings, what operating system you
                    are using, unique device identifiers and other diagnostic
                    data to help us support and improve upon the Services. We
                    use the information that you provide for such purposes as
                    responding to your requests. We receive and store certain
                    types of information whenever you interact with us. For
                    example, like many websites, we use "cookies," and we obtain
                    certain types of information when your web browser accesses
                    MadMaps or advertisements and other content served by or on
                    behalf of MadMaps on other Web sites.
                  </li>
                  <br />
                  <li>
                    <strong>Public Content</strong>: Your contributions to the
                    Service are intended for public consumption and are
                    therefore viewable by the public, including your photos,
                    ratings, reviews, tips, lists, collections/ preference,
                    compliments, comments, advertisements and edits to business
                    page information, lists, followers, the users you follow,
                    current and prior restaurant reservation details, food
                    ordering details and history, favorite restaurants, special
                    restaurant requests, contact information of people you add
                    to, or notify of, your restaurant reservations through our
                    Services, names, and other information you provide on our
                    Services, and other information in your account profile.
                  </li>
                  <br />
                  <li>
                    <strong>Contacts</strong>:You can invite others to join or
                    become members by providing us with their contact
                    information, or by allowing us to access contacts from your
                    computer, mobile device, or third party sites to select
                    which individuals you want to invite. If you allow us to
                    access your contacts, we will transmit information about
                    your contacts to our servers long enough to process your
                    invitations.&nbsp;
                  </li>
                  <br />
                  <li>
                    <strong>Communications</strong>:When you sign up for an
                    account or use certain features, please note that you will
                    receive certain administrative, transactional, or legal
                    messages from our website / application, which you cannot
                    opt out of. We may also track your actions in response to
                    the messages you receive from us or through the Service,
                    such as whether you deleted, opened, or forwarded such
                    messages, and share that information with other parties to
                    your messages (for example, to let them know if you have
                    viewed their message). When a user requests a quote or
                    similar information from multiple businesses through the
                    Service, we may disclose to those businesses information
                    about the status of the user’s requests, such as whether any
                    business has responded to the request, the type of response
                    provided (for example, a quote or a request for more
                    information), and whether the user has responded to any
                    business’s reply&nbsp;
                  </li>
                  <br />
                  <li>
                    <strong>Activity</strong>: We store information about your
                    use of the Service, such as your search activity, the pages
                    you view, the date and time of your visits, businesses you
                    call using our mobile applications, and reservations,
                    purchases, or transactions you make through the Service. We
                    also store information that your computer or mobile device
                    may provide to us in connection with your use of the
                    Service, such as your browser type, type of computer or
                    mobile device, browser language, IP address, WiFi
                    information such as SSID, mobile carrier, phone number,
                    unique device identifier, advertising identifier, location
                    (including geolocation, beacon based location, and GPS
                    location), and requested and referring URLs and mobile
                    applications. We may also receive and store your location
                    whenever our mobile applications are running, including when
                    running in the background, if you enable our mobile apps to
                    access such information in the course of using the Service.
                    You may be able to limit or disallow our use of certain
                    location data through your device or browser settings, for
                    example by adjusting the settings for our applications in
                    iOS or Android privacy settings.
                  </li>
                  <br />
                  <li>
                    <strong>Different Devices</strong>: You may access the
                    Service through different devices (e.g., your mobile phone
                    or personal computer) and different platforms. The
                    information that we collect and store through those
                    different uses may be cross-referenced and combined, and
                    your contributions through our website / application will
                    typically be similarly visible and accessible on all our
                    other platforms.
                  </li><br />
                  <li>
                    <strong>Professional Information</strong>: If you represent
                    a business on our website / application, we may collect and
                    display information about you related to your business
                    activities, such as a business name that may incorporate
                    your name, or a publicly available business address that is
                    also used as a home address. We will also collect and
                    display information about your professional background that
                    you choose to share with us through the Service, such as
                    information about your professional licenses or
                    certifications, or your professional history or specialties.
                    We would also be storing various advertisements developed by
                    service providers on our server for the purposes of
                    performing our duties and providing our services on our
                    platform. Further, we may use these advertisements to
                    repurpose on the Social Media & other marketing channels.
                  </li><br />
                  <li>
                    <strong>Third Party Data</strong>: We may receive
                    information about you from other sources, including publicly
                    available databases or from third parties from whom we have
                    purchased data, and combine this data with information we
                    already have about you. We may also receive information from
                    other affiliated companies that are a part of our corporate
                    group. This third-party data is obtained for our legitimate
                    business interests and helps us to update, expand and
                    analyze our records, identify new prospects for marketing,
                    and provide products and services that may be of interest to
                    you.
                  </li><br />
                  <li>
                    <strong>Device Information</strong>: When you use our online
                    and mobile Services, we may automatically collect
                    information about the type of device you use, for example,
                    the operating system version or the device identifier (or
                    "UDID").
                  </li><br />
                  <li>
                    <strong>Other necessary Information</strong>: We may also
                    require the following information in order to provide better
                    services to you:
                    <br />
                    <ul>
                      <li>
                        Information about how the user uses the MadMaps
                        platform, including the quality/quantity of services
                        being taken by user, time period for which services are
                        being taken, internet connection, meetings taking place;
                      </li>
                      <li>
                        Information in relation to the messages, interactions
                        via video/audio calls, recordings, and any other
                        information needed to provide technical support.
                      </li>
                    </ul>
                  </li><br />
                  <li>
                    <strong>Information from Other Sources</strong>: We might
                    receive information about you from other sources and add it
                    to our account information.&nbsp;
                    <br />
                  </li><br />
                  <li>
                    <strong>Usage and Log Data</strong>. We collect information
                    when you use our Services or view our advertising outside of
                    the platform. Examples of this information may include your
                    IP address, device, browser and software characteristics
                    (such as type and operating system), location (which may
                    include precise location data), activity on the application/
                    website.&nbsp;
                  </li><br />
                  <li>
                    <strong>Third-Party Data</strong>. We may collect
                    information about you from other sources, such as data
                    aggregators, public databases, and our business partners.
                    Examples of this may include information (whether
                    identified, de-identified or anonymous, and whether
                    collected online or offline) about your interests,
                    demographic data, purchasing behavior, and your activities
                    online (such as websites visited and advertisements viewed).
                    We may combine the information we collect from or about you
                    and use it in the manner described in this Privacy Policy or
                    as otherwise permitted by law.
                  </li><br />
                  <li>
                    <strong>Voluntary information.</strong> We may collect
                    additional information at other times, including but not
                    limited to, when you provide feedback, comments, change your
                    content or email preferences, respond to a survey, or any
                    communications with us.
                  </li><br />
                </ol>
              </li>
              <li>
                <strong>Sharing Information with Third Parties</strong>
                <br />
                We may share information collected from or about you with third
                parties as explained further below, including business partners,
                social networking services, service providers, advertisers, and
                other companies that are not affiliated with.
                <br />
                <ol type="a">
                  <li>
                    <strong>Social Networking Services</strong>. When you choose
                    to share information with social networking services about
                    your activities on our Services, information about you and
                    your activities will be shared with that social network.
                  </li>
                  <li>
                    <strong>Our Subsidiaries and affiliates</strong>. We may
                    share the information with our subsidiaries and affiliates,
                    which are entities under common ownership or control of our
                    ultimate parent company.
                  </li>
                  <li>
                    <strong>Buyers or successors of our business</strong>. In
                    the event of any merger, divestiture, restructuring,
                    reorganization, dissolution or other sale or transfer or all
                    of our assets, whether as a going concern or as part of
                    bankruptcy, liquidation or similar proceedings, we may share
                    such information with the buyer or successor of our
                    business.
                  </li>
                  <li>
                    <strong>Third parties</strong>. We may share the information
                    with third-parties to place advertisements and/or content
                    which markets their products or services to you if you have
                    consented to receive the promotional updates.
                  </li>
                  <li>
                    <strong>Service Providers</strong>. We may share the
                    information collected from or about you with companies that
                    provide services to us and our business partners, such as to
                    send you communications via emails, messages or tele-call to
                    inform you about our products that may be of interest to
                    you, push notifications to your mobile device on our behalf,
                    provide voice recognition services to process your spoken
                    queries and questions, help us analyze use of our Services,
                    and process.&nbsp;
                  </li>
                  <li>
                    <strong>Your Browsers and Devices</strong>. We may work with
                    service providers who use information collected from or
                    about you to help us identify the browsers and devices you
                    use for various purposes, including for targeted
                    advertising, analytics, attribution, and measurement
                    purposes. For example, these service providers may use your
                    activity on one browser or device to serve you with more
                    tailored advertising on another browser or device.&nbsp;
                  </li>
                  <li>
                    <strong>Advertising-Related Service Providers</strong>. We
                    may share the information collected from or about you in
                    encrypted, aggregated, or de-identified forms with
                    advertisers and service providers that perform
                    advertising-related services for us and our business
                    partners in order to tailor advertisements, measure and
                    improve advertising effectiveness, and enable other
                    enhancements. This information includes your use of the
                    Application/website of MadMaps, websites you visited,
                    advertisements you viewed, and your other activities online.
                  </li>
                  <li>
                    <strong>To Protect Your or Our Rights</strong>. In some
                    instances, we may disclose information from or about you
                    without providing you a choice. For example, we may disclose
                    your information in the following ways: to protect the our
                    legal rights and our affiliates or partners, and each of our
                    respective equity holders, directors, officers, employees,
                    agents, and business partners; to protect the safety and
                    security of users of the our Services or third parties; to
                    enforce our Terms of Use; to protect against fraud; for risk
                    management purposes; and to comply with or respond to the
                    law or legal process or a request for cooperation by a
                    government entity, whether or not legally required. If you
                    notify us that you believe your legal rights have been
                    violated by us or another user of the MadMaps, we may
                    provide the information that you provide to us to others to
                    the extent that we believe it is necessary to evaluate and
                    respond to your complaint.
                  </li>
                  <li>
                    <strong>Legal Purposes</strong>. We may share your
                    information when we believe in good faith that such sharing
                    is reasonably necessary in order to investigate, prevent, or
                    take action regarding possible illegal activities or to
                    comply with legal process. We may also share your
                    information to investigate and address threats or potential
                    threats to the physical safety of any person, to investigate
                    and address violations of this Privacy Policy or the Terms
                    of Service, or to investigate and address violations of the
                    rights of third parties and/or to protect the rights,
                    property and safety of our company, our employees, users, or
                    the public. This may involve the sharing of your information
                    with law enforcement, government agencies, courts, and/or
                    other organizations on account of legal requests such as
                    subpoena, court order or government demand to comply with
                    the law.
                  </li>
                  <li>
                    <strong>When You Choose to Share</strong>. In addition to
                    the sharing described in this Privacy Policy, we may share
                    any information from or about you with any party when we
                    have your consent or when you otherwise choose to share such
                    information. Also, certain functions within the our website
                    / application MadMaps may give you the option to share
                    information about your activities on the MadMaps services
                    through any sharing function.
                  </li>
                </ol>
              </li><br/>
              <li>
                <strong>Rights and Information of the Subscribers</strong>
                <br />
                Subscriber of MadMaps has a right to alter, correct or update
                his personal or non-personal information including details about
                your account on our application/&nbsp;website MadMaps, payment
                information and other related information. Deletion of personal
                information may be requested by you as and when required. We
                would attempt to respond to all requests relating to such issues
                in accordance with law. We retain the right to reject any
                unreasonable or unrealistic requests, requests that could expose
                us to fraud in any manner or require unnecessary technical
                support. We promise to prevent and take measures in order to
                dispose of any personal information of the user when not
                required.
              </li><br/>
              <li>
                <strong>Advertising</strong>
                <br />
                We place advertisements on both our application as well as on
                other platforms and provide services in the form of displaying
                information and contents of other third party business or
                websites often enabling users to interact with the
                advertisements or click through to websites or other properties
                owned or operated by other companies. This section provides
                additional information about the collection and use of
                information from or about you in connection with such
                advertising.
                <br />
                <br />
                <strong>Viewing Ads</strong>. When you view an advertisement,
                whether or not you click on it, or when you interact with an
                advertisement, the advertiser, advertising technology companies,
                and service providers that perform advertising-related services
                for us and our business partners may collect information from or
                about you. The information practices of these third parties are
                governed by their own privacy policies and are not governed by
                this Privacy Policy. Please be aware that we do not notify you
                when you have chosen to click through to another website or
                property when using our Services. We recommend that you
                familiarize yourself with the privacy policies of such websites
                or properties to determine how they use or share any information
                they separately collect from you.
              </li><br />
              <li>
                <strong>Opt-Out Options</strong>
                <br />
                Users have the following choices regarding how we and third
                parties use certain information collected from or about you. We
                are committed to complying with the Digital Advertising
                Alliance,&nbsp; Self-Regulatory Principles for Online Behavioral
                Advertising and the DAA Application of Self-Regulatory
                Principles for the Mobile Environment.
                <br />
                <br />
                <strong>Opt-Out from Promotional Communications</strong>. You
                may opt out of receiving promotional communications from MadMaps
                by sending an email to{" "}
                <a href="mailto:support@gmadmaps.in">support@gmadmaps.in</a>.
                You may also opt out of certain promotional communications by
                clicking the "unsubscribe" link in a particular promotional
                communication. Please note that you cannot opt out from
                receiving all communications from MadMaps, including
                administrative messages, service announcements, and messages
                regarding the terms and conditions of your account because those
                may contain important information.
                <br />
                <br />
                <strong>Direct MailingYou</strong> can sign up for our
                newsletter through a personal email address at any of the
                sign-up boxes featured throughout the website or via our User
                Registration page. By providing your email address you agree to
                receive regular newsletter emails from MadMaps.
              </li><br/>
              <li>
                <strong>Storing Your Personal Data</strong>
                <br />
                All the information you provide to us is stored on our secured
                servers. However, the password you create for your account
                should be confidential and you should not share your password
                with anyone.To ensure complete security, we suggest you use
                integrated domains such as Gmail and Facebook. Once we receive
                your information, we will use strict procedures and security
                features to try preventing unauthorized access.
                <br />
                <br />
                All the information you provide to us is stored on our secured
                servers. However, the password you create for your account
                should be confidential and you should not share your password
                with anyone.
                <br />
                <br />
                To ensure complete security, we suggest you use integrated
                domains such as Gmail and Facebook. Once we receive your
                information, we will use strict procedures and security features
                to try preventing unauthorized access.
              </li><br/>
              <li>
                <strong>Automatic Transactional Information.&nbsp;</strong>
                <br />
                Certain Non-personal Information may be collected automatically
                as part of your use of this Site, as well as from your
                transactions with us and our affiliates or non-affiliated third
                parties. This Non-personal Information is used to improve the
                overall Site operations, functionality and appearance.
                Non-personal Information may include your IP address, web
                browser type, domain name and the time or date upon which you
                visited the Site.
                <br />
                <br />
                We currently contract with online partners to help manage and
                optimize our Internet business and communications. We use the
                services of a marketing company to help us measure the
                effectiveness of our advertising and how visitors use our site.
                To do this, we use Web beacons and cookies provided by our
                marketing company on this site. By supplementing our records,
                this information helps us learn things like what pages are most
                attractive to our visitors, which of our products most interest
                our customers, and what kinds of offers our customers like to
                see.
              </li><br/>
              <li>
                <strong>Payment Gateway&nbsp;</strong>
                <br />
                Information relating to electronic transactions entered via the
                Website shall be protected by encryption technology.
                <br />
                <br />
                We have partnered with Razorpay which is a secured payment
                gateway. The Website cannot interfere and does not interfere
                with the payment gateway mechanism / tools / application. The
                Website has no access to the information that you may enter for
                making the payment through the payment gateway. Your transaction
                and banking details or other information as required for
                internet banking or other payment instruments are held by our
                Payment Gateway partner. By creating a link to a payment
                gateway, we do not endorse the payment gateway, nor are we
                liable for any failure of products or services offered by such a
                payment gateway. Such a payment gateway may have a privacy
                policy different than ours. All failures / errors / omissions of
                the payment gateway shall be solely on the payment gateway. You
                hereby consent that you shall not make liable the MadMaps Travel
                Tech Pvt. Ltd. for any disputes that you may have with the
                payment gateway for any wrongdoing of the payment gateway
              </li><br/>
              <li>
                <strong>Accounts, Deposits, Disbursements</strong>.<br />
                10.1. Digital Payments Wallet: MadMaps has created a Digital
                Payments Wallet which will be used to collect payments from the
                Customers. MadMaps confirms and undertakes that it will solely
                operate the Digital Payments Wallet of the Customer and at all
                times the beneficiary of the funds in the Digital Payments
                Wallet will be to the relevant MadMaps Customer. The funds in
                the Digital Payments Wallet will not be accessible to MadMaps at
                any time during and after the term of this Agreement and in the
                event that MadMaps becomes insolvent, the funds in the Digital
                Payments Wallet will not form part of MadMaps’s assets and thus
                will not be subject to distribution and administration. MadMaps
                further undertakes to operate the Digital Payments Wallet fully
                and truthfully. when the customer uses the wallet to pay for an
                ad purchase, that money will credited to MadMaps
                <br />
                10.2. Payment Gateway: MadMaps has created a Digital Payments
                Wallet with a licensed financial institution (i.e. PayU) and
                such Institutions has expressly agreed to licence its Services
                to MadMaps for the purpose of enabling MadMaps to avail its
                services. MadMaps has ensured that it has entered into the
                relevant contractual arrangements with the Institution prior to
                onboarding any Customer. The Customer undertakes to be bound by
                the additional Terms and Conditions of the Institution.
                <br />
                10.3. Deposits: The Customer can deposit an amount to the
                Digital Payments Wallet using an account number provided by the
                Financial Institution. The Customer shall also be required to
                share the payment details with MadMaps to simultaneously update
                its MadMaps Wallet. Notwithstanding, MadMaps may receive a
                similar notification from the designated Financial Institution
                and update the Customer’s wallet on the same.
                <br />
                10.4. Disbursements: MadMaps shall facilitate disbursements
                through the Institution’s Digital Payments Wallet upon receiving
                instructions from the Customer’s account on MadMaps. Such
                disbursement instructions shall be initiated by the designated
                employees of the Customer that have been assigned rights to
                approve disbursements by the Customer’s authorized
                representatives. The instructions shall include but are not
                limited to amount to be disbursed, disbursement channel, reason
                for disbursement, date of disbursement, approver, requester,
                account to be credited, till to be credited, paybill to be
                credited, and mobile money number to be credited which
                information shall be provided for in the Customer’s account on
                MadMaps. This notwithstanding, the Customer acknowledges that
                any and all disbursements instructions shall be affected only
                through the Customer and its designated employees, agents or
                directors and not by MadMaps.
                <br />
                10.5. Return of Deposits. The Customer may withdraw or recall
                part or all of his deposits or funds from the Digital Payments
                Wallet. MadMaps shall return the Customer’s funds if the
                Agreement is terminated either by the Customer or by itself or
                by the Institution. The Institution can also return the
                Customer’s funds where the Customer fails to comply with its
                terms and conditions.
              </li><br/>
              <li>
                <strong>Wallet Money</strong>.&nbsp;
                <br />
                When the customer uses our services, he/she shall be awarded
                with credit money depending upon the nature / amount of the
                transaction and the policy of the company at that time. This
                money may be in the form of cash backs, discounts and offers
                which will be stored in your digital wallet and which have to be
                redeemed within a stipulated period of time which will vary from
                one transaction to another. The wallet money can only be used to
                avail services on the application and cannot be redeemed in the
                form of cash by the customer. After expiry, the points won’t be
                transferred back to the company, they will simply cease to exist
                and will not benefit either of the parties.
              </li><br/>
              <li>
                <strong>Data Retention</strong>.&nbsp;
                <br />
                We will retain copies of your information for as long as you
                maintain your account or as necessary in connection with the
                purposes set out in this policy, unless applicable law requires
                a longer retention period. In addition, we may retain your
                information for the duration of any period necessary to
                establish, exercise or defend any legal rights.
              </li><br/>
              <li>
                <strong>Applicable Law</strong>.&nbsp;
                <br />
                We are headquartered in Gurugram, Haryana, India and the
                Services are intended for mainly consumer services / user &
                service providers in India and across the globe. By viewing any
                Content or otherwise using our services, you consent to the
                transfer of information throughout India to the extent
                applicable, and the collection, storage, and processing of
                information under Indian laws.
              </li><br/>
              <li>
                <strong>Updates / Changes</strong>.&nbsp;
                <br />
                The internet is an ever evolving medium. We may alter our Policy
                from time to time to incorporate necessary changes in
                technology, applicable law or any other variant. In any case, we
                reserve the right to change (at any point of time) the terms of
                this Policy or the Terms of Use. Any changes we make will be
                effective immediately on notice, which we may give by posting
                the new policy on the Sites. Your use of the Sites or Services
                after such notice will be deemed acceptance of such changes. We
                may also make reasonable efforts to inform you via electronic
                mail. In any case, you are advised to review this Policy
                periodically on the Sites to ensure that you are aware of the
                latest version.
              </li><br/>
              <li>
                <strong>Policy Related To Minors</strong>.<br />
                We do not knowingly collect personal information from minors
                under the age of 18. Minors are not permitted to use our website
                or Services, and we request that minors under the age of 18 not
                submit any personal information to the website. Since
                information regarding minors under the age of 18 is not
                collected, we do not knowingly distribute personal information
                regarding minors under the age of 18.
              </li><br/>
              <li>
                <strong>Contacting us</strong>
                <br />
                At MadMaps, we are committed towards ensuring that disputes
                between Us and the Users of Madmaps platform are settled
                amicably by way of the dispute resolution mechanisms and
                procedures. However, in the event that You wish to contact us,
                You may proceed to do so by reaching out to customer support at
                +91 9818045110 (CUSTOMER CARE NUMBER) or access help center
                at&nbsp;
                <a href="mailto:support@madmaps.in">support@madmaps.in</a>{" "}
                (CONTACT US LINK).
                <br />
                <br />
                Thank you for taking the time to read this Privacy Policy. We
                hope you enjoy our Services!
              </li>
              <br />
              <li>
                <strong>General Disclaimer</strong>.&nbsp;
                <br />
                We engage various banks and payment gateway providers, the data
                of the User, may be collected by them or be stored on their
                servers. These third parties have security measures in place to
                protect the loss, misuse and alteration of the information,
                details of which are available at their respective website. We
                are not responsible for the use or storage of such data or
                security measures adopted by these third parties. In the event
                you have questions or concerns about the security measures
                adopted by such third parties, you can contact them or their
                data protection / privacy team / legal team or their designated
                the grievance officer directly, whose contact details may be
                available in their respective privacy policy or their website,
                or you can also write to our Grievance Officer at the address
                provided above.
              </li>
              <br />
            </ol>
          </div>
          <div className="col-md-12">
            <h2 className="text-left my-5">Cookie Policy</h2>
            <p>
              MADMAPS uses cookies to personalize your experience on the Website
              and the advertisements that may be displayed. MADMAPS’s use of
              cookies is similar to that of any other reputable online
              companies.
            </p>
            <br />
            <br />
            <h4>What are cookies?</h4>
            <br />
            <p>
              Cookies are small pieces of information that are stored by your
              browser on your device's hard drive. Cookies allow us to serve you
              better and more efficiently. Cookies also allow ease of access, by
              logging you in without having to type your login name each time
              (only your password is needed); we may also use such cookies to
              display any advertisement(s) to you while you are on the our
              website or to send you offers (or similar emails – provided you
              have not opted out of receiving such emails) focusing on
              destinations which may be of your interest.
            </p>
            <br />
            <p>
              A cookie may also be placed by our advertising servers, or
              third-party advertising companies. Such cookies are used for
              purposes of tracking the effectiveness of advertising served by us
              on any website, and also to use aggregated statistics about your
              visits to the Website in order to provide advertisements in the
              Website or any other website about services that may be of
              potential interest to you. The third-party advertising companies
              or advertisement providers may also employ technology that is used
              to measure the effectiveness of the advertisements. All such
              information is anonymous.{" "}
            </p>
            <br />
            <p>
              They may use this anonymous information about your visits to the
              Website in order to provide advertisements about goods and
              services of potential interest to you. No Personal Information is
              collected during this process. The information so collected during
              this process, is anonymous, and does not link online actions to a
              User Most web browsers automatically accept cookies. Of course, by
              changing the options on your web browser or using certain software
              programs, you can control how and whether cookies will be accepted
              by your browser. MADMAPS supports your right to block any unwanted
              Internet activity, especially that of unscrupulous websites.
              However, blocking MADMAPS cookies may disable certain features on
              the Website, and may hinder an otherwise seamless experience to
              purchase or use certain services available on the Website. Please
              note that it is possible to block cookie activity from certain
              websites while permitting cookies from websites you trust
            </p>
            <br />
            <p>
              Please note that our use of any information we collect about you
              through your use of cookies is subject to our Privacy Policy,
              which is linked to from the bottom of every page.
            </p>
            <br />
            <h4>Automatic Logging of Session Data:</h4>
            <br />
            <p>
              Each time you access the Website your session data gets logged.
              Session data may consist of various aspects like the IP address,
              operating system and type of browser software being used and the
              activities conducted by the User while on the Website. We collect
              session data because it helps us analyze User’s choices, browsing
              patterns including the frequency of visits and duration for which
              a User is logged on. It also helps us diagnose problems with our
              servers and lets us better administer our systems. The aforesaid
              information cannot identify any User personally. However, it may
              be possible to determine a User's Internet Service Provider (ISP),
              and the approximate geographic location of User's point of
              connectivity through the above session data.
            </p>
            <br />
            <h4>What types of cookies do we use?</h4>
            <br />
            <p>
              The cookies we use fall into 3 categories. These categories are
              described below.
            </p>
            <br />
            <h4>Essential cookies</h4>
            <br />
            <p>
              These cookies are necessary to help you access and move around our
              site and use all its features. Without these cookies, our website
              would not work properly and you would not be able to use certain
              important features. For example, we use a cookie to keep you
              logged in during your visit, so the site does not require you to
              log in repeatedly to access different pages. We also use essential
              cookies for fraud detection and prevention purposes.
            </p>
            <br />
            <h4>Analytics and customization cookies</h4>
            <br />
            <p>
              At MadMaps, we want to make your experience on our website as
              helpful, smooth and as enjoyable as possible. We use cookies to
              help us understand how our website is being used and how we can
              improve your experience on it. This type of so-called "analytics"
              cookies can provide us with anonymous information to help us
              understand which parts of our Website interest our visitors and if
              they experience any errors. We use these cookies to test different
              designs and features for our websites and we also use them to help
              us monitor how our visitors reach our websites. We also use
              cookies to save your settings and preferences on our Website, such
              as language preference and information you've previously entered
              when searching for travel options. Some customization cookies are
              essential if you want to use certain features of the website.
            </p>
            <br />
            <h4>Advertising cookies</h4>
            <br />
            <p>
              Advertising cookies help ensure that the advertisements you see on
              our Web site are as relevant to you as possible. For example, some
              advertising cookies help select advertisements that are based on
              your interests. Others help prevent the same advertisement from
              continuously reappearing for you.
            </p>
            <br />
            <h4>
              How do I refuse or withdraw my consent to the use of cookies?
            </h4>
            <br />
            <p>
              Advertising cookies help ensure that the advertisements you see on
              our Web site are as relevant to you as possible. For example, some
              advertising cookies help select advertisements that are based on
              your interests. Others help prevent the same advertisement from
              continuously reappearing for you.
            </p>
            <br />
            <h4>Advertising cookies</h4>
            <br />
            <p>
              Most cookies are ‘session cookies’, which means they are
              automatically deleted from your hard drive at the end of a
              session. You are always free to decline our cookies if your
              browser permits, although in that case you may not be able to use
              certain features on the website and you may be required to
              re-enter your password more frequently during a session.
              Additionally, you may encounter cookies or other similar devices
              on certain pages of the website that are placed by third parties.
              We do not control the use of cookies by third parties.
            </p>
            <br />
            <p>
              Most advertising networks offer you a way to opt out of
              advertising cookies.
            </p>
            <br />
            <p>
              If you wish to remove previously-stored Cookies, you can manually
              delete the Cookies at any time.
            </p>
            <br />
            <h4>How you can contact us</h4>
            <br />
            <p>
              If you have questions about our use of cookies, please write to us
              at:<a href="mailto:support@gmadmaps.in">support@gmadmaps.in</a>.
            </p>
            <br />
          </div>
        </div>
      </div>
      <section className="footer-area">
        <div className="container">
          <div className="footer-content">
            <div className="footer-about mt-30">
              <a href="#">
                <img
                  src="assets2/images/madmapswebsite/Logo-new.png?v=1.1"
                  alt=""
                />
              </a>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/madmaps-apps"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/_madmaps" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
              <p className="pl-md-5 ml-md-5">@MadMaps-2021</p>
            </div>
          </div>
        </div>
      </section>

      <a className="back-to-top">
        <i className="fal fa-angle-up"></i>
      </a>
    </div>
  );
};
export default PrivacyPolicy;
