import { TextField, Typography, Select, MenuItem, Button, Snackbar } from '@material-ui/core'
import React from 'react'
import ImageUploader from "react-images-upload";
import Alert from '@material-ui/lab/Alert';

import {ReactComponent as Template1} from '../assests/template1.svg'
import {ReactComponent as Template2} from '../assests/template2.svg'
import {ReactComponent as Template3} from '../assests/template3.svg'
import {ReactComponent as Template4} from '../assests/template4.svg'

const template = [
    {
        name : "Template 1",
        id : "template1",
        preview : <Template1 />
    },
    {
        name : "Template 2",
        id : "template2",
        preview : <Template2 />
    },
    {
        name : "Template 3",
        id : "template3",
        preview : <Template3 />
    },
    {
        name : "Template 4",
        id : "template4",
        preview : <Template4 />
    },
]

const SaveBtnStyle = {
    borderRadius : 50,
    padding : "5px 25px",
    color : "#fdba27",
    borderColor : "#fdba27"
}

const SaveBtnHoverStyle = {
    borderRadius : 50,
    padding : "5px 25px",
    backgroundColor : "#fdba27",
    color : 'white'
}

class AddNewAd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            logo : "",
            photo : "",
            template : template[0],
            title : "",
            disc : "",
            offer : "",
            address : "",
            addList : [],
            saveVarient : "outlined",
            loader : false,
            AlertOpen : false,
            AlertMsg : "",
            AlertSeverity : ""

        }
    }

    componentDidMount = () => {
        this.getAddress()
    }

    // extract selected users saved addresses 
    getAddress = () => {
        let addList = []
        let listings = this.getter('listings');
        Object.entries(listings).map((k,i) => {
            addList.push(k[1])
        })
        this.setState({addList : addList})
    }

    // Handle getting values like user Id, phone number, user listings, etc from parent component
    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }


    // Handle Template selection
    handleTempSelect = (event) => {
        this.setState({template: event.target.value})
    }

    // Handle Address selection from the dropdown or select 
    handleAddSelect = (event) => {
        this.setState({address : event.target.value})
    }

    // Handle picture upload for logo and photo
    //  @param path : logo or photo
    onDrop = (picture, path) => {
        if (path === "logo") {
            this.setState({logo : picture[0]})
        } else if (path === "photo") {
            this.setState({photo : picture[0]})
        }
    }

    // handles uploading of logo and background image with other Ad data 
    handleSave = () => {
        if (this.state.address === "" || this.state.address === null || this.state.address === "null") {
            this.setState({AlertOpen: true, AlertMsg : "Please select your listing address", AlertSeverity : "warning"})
        } else if (this.state.template.id !== "template3" && (!this.state.photo.name || !this.state.logo.name || !this.state.title || !this.state.disc || !this.state.offer)) {
            if (!this.state.title) {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Ad Title", AlertSeverity : "warning"})
            } else if (!this.state.disc) {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Description", AlertSeverity : "warning"})
                
            } else if (!this.state.offer) {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Offer", AlertSeverity : "warning"})
                
            } else if (!this.state.photo.name) {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Ad Image", AlertSeverity : "warning"})
                
            } else  {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Logo Image", AlertSeverity : "warning"})
            }
            
        } else if (this.state.template.id === "template3" && (!this.state.photo.name || !this.state.logo.name) ) {
           
            if (!this.state.photo.name) {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Ad Image", AlertSeverity : "warning"})
            } else  {
                this.setState({AlertOpen: true, AlertMsg : "Please Add Logo Image", AlertSeverity : "warning"})
            }
            
        } else if (this.state.template.id === "template4" && (this.getter("phone") === "")) {
            this.setState({AlertOpen: true, AlertMsg : "This Template requires client phone number please update.", AlertSeverity : "warning"})
        } else {
            this.setState({loader : true})
            let userID = this.state.data.id
            let data = {}
            let Credit = 0
            let app = this.props.app
            app.database().ref(`users/${userID}/credit`).once('value', (snapshot) => {
                if (snapshot.exists()) {
                    Credit = snapshot.val()
                    this.setState({AlertOpen: true, AlertMsg : "Checking Credits", AlertSeverity : "info"})
                    }
                }).then(async () => {
                    if (Credit > 0) {
                        data["title"] = this.state.title
                        data["description"] = this.state.disc
                        data["offers"] = this.state.offer
                        data["template"] = this.state.template.id
                        data["phone"] = this.getter("phone")
                        data["publish"] = Math.floor(Date.now() /1000)
                        let photoFormat = this.state.photo.type.split('/')[1]
                        let photoName = `madmap-listing-${this.state.data.id}-photo-${data["publish"]}.${photoFormat}`
                        
                        let logoFormat = this.state.photo.type.split('/')[1]
                        let logoName = `madmap-listing-${this.state.data.id}-photo-${data["publish"]}.${logoFormat}`

                        let reference =  app.storage().ref().child(`usersData/${userID}/story/${photoName}`)
                        let logoRef = app.storage().ref().child(`usersData/${userID}/logo/${logoName}`)
                        //
                        this.setState({AlertOpen: true, AlertMsg : "Uploading Photo ....", AlertSeverity : "info"})
                        await reference.put(this.state.photo).then(async (snapshot) => {
                            let downloadUrl = await reference.getDownloadURL()
                            data["photo"] = downloadUrl
                            this.setState({AlertOpen: true, AlertMsg : "Photo Uploaded ...", AlertSeverity : "info"})
                        }).then(async () => {
                            this.setState({AlertOpen: true, AlertMsg : "Uploading Logo ....", AlertSeverity : "info"})
                            await logoRef.put(this.state.logo).then(async () => {
                                let downloadUrl = await logoRef.getDownloadURL()
                                data["logo"] = downloadUrl
                                this.setState({AlertOpen: true, AlertMsg : "Logo Uploaded ...", AlertSeverity : "info"})
                            })
                        })
                        .then(() => {
                            app.database().ref('ui/deduct/credit').once('value', (childSnapshot) => {
                                if (childSnapshot.exists()) {
                                    let valueRemain = Credit - childSnapshot.val()
                                    app.database().ref(`users/${userID}`).update({credit : valueRemain})
                                    this.setState({AlertOpen: true, AlertMsg : "Credits Updated ...", AlertSeverity : "info"})
                                }
                            })

                            app.database().ref(`users/${userID}/listingStory`).once('value', (snapshot) => {
                                if (snapshot.exists()) {
                                    const storyId = app.database().ref(`users/${userID}/listings/${this.state.address}/listingStory`).push(data)
                                    app.firestore().doc('madMap/markers').set({
                                        markers : {
                                            [this.state.address] : {
                                                listingStory : {
                                                    [storyId.key] : data
                                                }
                                            }
                                        }
                                    }, {merge : true})
                                    this.setState({AlertOpen: true, AlertMsg : "Updating Story to database ...", AlertSeverity : "info"})
                                }else {
                                    const storyId = app.database().ref(`users/${userID}/listings/${this.state.address}/listingStory`).push(data)
                                    app.firestore().doc('madMap/markers').set({
                                        markers : {
                                            [this.state.address] : {
                                                listingStory : {
                                                    [storyId.key] : data
                                                }
                                            }
                                        }
                                    }, {merge : true})
                                    this.setState({AlertOpen: true, AlertMsg : "Updating Story to database ...", AlertSeverity : "info"})
                                }

                            }).then(() => {
                                this.setState({loader : false,
                                    disc : "",
                                    logo : "",
                                    photo : "",
                                    title : "",
                                    offer : ""
                                })
                                this.setState({AlertOpen: true, AlertMsg : "Story Updated Successfully !!!", AlertSeverity : "success"})
                            })
                        })
                    } else {
                        this.setState({loader : false})
                        this.setState({AlertOpen: true, AlertMsg : "No Credit Left", AlertSeverity : "error"})
                    }
                }
            )
        }
    }


    render = () => {
        return (
            <div style={{display:'flex', flex:1, gap:20}}>
                <div style={{flex:0.5, gap:10, display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography>Select Template</Typography>
                        <Select style={{minWidth:227}} variant="outlined" defaultValue={template[0]} onChange={(e) => this.handleTempSelect(e)}>
                            {template.map((k,i) => 
                                <MenuItem value={template[i]}>{k.name}</MenuItem>
                            )}
                        </Select>
                    </div>
                    {this.state.template.id !== "template3" ?
                        <div style={{display:"flex", flexDirection:'column', gap:10}}>
                            <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                <Typography>Ad Title</Typography>
                                <TextField variant="outlined" onChange={(e) => this.setState({title : e.target.value})} />
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                <Typography>Ad Description</Typography>
                                <TextField variant="outlined" onChange={(e) => this.setState({disc : e.target.value})} />
                            </div>
                            <div style={{display:'flex', flex:1, justifyContent:'space-between'}}>
                                <Typography>Offers</Typography>
                                <TextField variant="outlined" onChange={(e) => this.setState({offer : e.target.value})} />
                            </div>
                        </div> : null }
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography>Select Address</Typography>
                        <Select style={{minWidth:227}} variant="outlined" onChange={(e) => this.handleAddSelect(e)}>
                            {this.state.addList.map((k,i) => 
                                <MenuItem value={k.geohash}>{k.locationName}</MenuItem>
                            )}
                        </Select>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <ImageUploader
                            withIcon={false}
                            buttonText='Upload Logo'
                            onChange={(event) => this.onDrop(event, 'logo')}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            singleImage
                            maxFileSize={1048576}
                        />
                        <ImageUploader
                                withIcon={false}
                                buttonText='Upload Photo'
                                onChange={(event) => this.onDrop(event, 'photo')}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                singleImage
                                maxFileSize={5242880}
                            />
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        
                    </div>
                </div>
                <div style={{flex:0.5}}>
                    <div style={{display:'flex', flex:1}}>
                        <div style={{flex:0.5}}>
                            <Typography>Template Preview</Typography>
                            {this.state.template.preview}
                        </div>
                        <div style={{flex:0.5}}>
                            <Typography>Photo Preview</Typography>
                            <img src={this.state.photo !== "" ? URL.createObjectURL(this.state.photo) : ""} style={{width: 120,
                                                                    height: 259,
                                                                    objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div style={{ flex:1}}>
                        <Typography>Logo Preview</Typography>
                        <img src={this.state.logo !== "" ? URL.createObjectURL(this.state.logo) : ""} style={{width: 50,
                                                                height: 50,
                                                                objectFit: 'cover'}} />
                    </div>
                    <div style={{display:'flex', flex:1, justifyContent:'flex-end', alignItems:'center'}}>
                        <Button onClick={this.handleSave} variant={this.state.saveVarient} style={this.state.saveVarient === "outlined" ? SaveBtnStyle : SaveBtnHoverStyle}
                            onMouseEnter={() => this.setState({saveVarient : "contained"})}
                            onMouseLeave={() => this.setState({saveVarient : "outlined"})}
                        >SAVE</Button>
                    </div>
                    
                </div>
                <Snackbar open={this.state.AlertOpen} autoHideDuration={6000} onClose={() => this.setState({AlertOpen : false})}>
                    <Alert onClose={() => this.setState({AlertOpen : false})} severity={this.state.AlertSeverity}>
                        {this.state.AlertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default AddNewAd