import { Divider, Typography, AppBar, Link, Toolbar, Avatar, Box, ButtonBase, Menu, MenuItem, Hidden, IconButton, SvgIcon, Drawer, withStyles, Card } from '@material-ui/core';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import React from 'react';
import { withRouter, Redirect, } from 'react-router';
import {  NavLink, HashRouter, Route } from "react-router-dom";
import app from '../../firebase/firebaseConfig';
import logo from '../../logo.svg'
import { Menu as MenuIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Account from './Account'
import AdsManagement from './adsManagement/AdsManagement';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import {
    Briefcase as BriefcaseIcon,
    Calendar as CalendarIcon,
    ShoppingCart as ShoppingCartIcon,
    Folder as FolderIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    UserPlus as UserPlusIcon,
    AlertCircle as AlertCircleIcon,
    Trello as TrelloIcon,
    Database as AdsIcon,
    User as UserIcon,
    Layout as LayoutIcon,
    Edit as EditIcon,
    DollarSign as DollarSignIcon,
    Mail as MailIcon,
    MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    Share2 as ShareIcon,
    Users as UsersIcon
} from 'react-feather';
import Settings from './settings';
import Users from './Users';
import Madstuff from './madStuff';
import Wallet from './wallet';
import ViewListings from "./viewListings";

const useStyles = (theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        display: 'flex',
        flex: '1'
    }
})

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: false,
            uID: null,
            Redirect: false,
            userName: null,
            userPhoto: null,
            menuOpen: false,
            anchorEl: null,
            openNav: false,
            sensorData: null
        }
    }


    componentDidMount() {
        document.title = 'MadMap Dashboard'
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user: true, uID: user.uid, userName: user.displayName.split(" ")[0], userPhoto: user.photoURL })
            } else {
                this.setState({ Redirect: true })
            }
        })
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null, menuOpen: false })
    }

    handleLogout = async () => {
        await app.auth().signOut().then(() => {
            this.setState({ user: false, Redirect: true })
        })

    }
    render() {
        if (this.state.user) {
            const { classes } = this.props;
            const content = (
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    <PerfectScrollbar options={{ suppressScrollX: true }} style={{ height: '100%' }}>
                        <Hidden lgUp>
                            <Box
                                p={2}
                                display="flex"
                                justifyContent="center"
                            >
                                <Link to="/">
                                    <logo />
                                </Link>
                            </Box>
                        </Hidden>
                        <Box p={2}>
                            <Box
                                display="flex"
                                justifyContent="center"
                            >
                                <Avatar
                                    alt="User"
                                    className={classes.avatar}
                                    src={this.state.userPhoto}
                                />
                            </Box>
                            <Box
                                mt={2}
                                textAlign="center"
                            >
                                {this.state.userName}

                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >

                                </Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <List>
                                <NavLink exact style={{ textDecoration: 'none', color: '#adb0bb', }} activeStyle={{
                                    fontWeight: 500,
                                    color: "#FDBA27"
                                }} to='/'>
                                    <ListItem button key={2}>
                                        <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><LayoutIcon size='1rem' /></ListItemIcon>
                                        <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Mad Library</Typography>
                                    </ListItem>
                                </NavLink>
                                {/* <NavLink exact style={{textDecoration:'none', color:'#adb0bb', }} activeStyle={{
                                    fontWeight: 500,
                                    color: "##FDBA27"
                                }}  to='/users'>
                            <ListItem button key={2}>
                                <ListItemIcon style={{color:'inherit', minWidth:25}}><UsersIcon size='1rem' /></ListItemIcon>
                                <Typography style={{fontWeight:500, fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',letterSpacing:0, lineHeight:1.75, fontSize:'.875rem'}}>User Management</Typography>
                            </ListItem>
                        </NavLink> */}

                                <NavLink exact style={{ textDecoration: 'none', color: '#adb0bb', }} activeStyle={{
                                    fontWeight: 500,
                                    color: "#FDBA27"
                                }} to='/wallet'>
                                    <ListItem button key={2}>
                                        <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><AccountBalanceWalletOutlinedIcon style={{ fontSize: '1rem' }} /></ListItemIcon>
                                        <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Wallet Management</Typography>
                                    </ListItem>
                                </NavLink>
                                <NavLink exact style={{ textDecoration: 'none', color: '#adb0bb', }} activeStyle={{
                                    fontWeight: 500,
                                    color: "#FDBA27"
                                }} to='/ads'>
                                    <ListItem button key={2}>
                                        <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><AdsIcon size="1rem"/></ListItemIcon>
                                        <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Ads Management</Typography>
                                    </ListItem>
                                </NavLink>
                                <NavLink exact style={{ textDecoration: 'none', color: '#adb0bb', }} activeStyle={{
                                    fontWeight: 500,
                                    color: "#FDBA27"
                                }} to='/settings'>
                                    <ListItem button key={2}>
                                        <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><TrelloIcon size='1rem' /></ListItemIcon>
                                        <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Settings</Typography>
                                    </ListItem>
                                </NavLink>

                                {/*<NavLink exact style={{ textDecoration: 'none', color: '#adb0bb', }} activeStyle={{*/}
                                {/*    fontWeight: 500,*/}
                                {/*    color: "#FDBA27"*/}
                                {/*}} to='/viewListings'>*/}
                                {/*    <ListItem button key={2}>*/}
                                {/*        <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><AccountBalanceWalletOutlinedIcon style={{ fontSize: '1rem' }} /></ListItemIcon>*/}
                                {/*        <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>View Listings</Typography>*/}
                                {/*    </ListItem>*/}
                                {/*</NavLink>*/}

                            </List>
                        </Box>

                    </PerfectScrollbar>
                    <Card variant='outlined' style={{ zIndex: 1200 }}>
                        <Box p={1}>
                            <NavLink style={{ textDecoration: 'none', color: '#adb0bb' }} activeStyle={{
                                fontWeight: 500,
                                color: "#FDBA27"
                            }} to='/account'>
                                <ListItem button key={2}>
                                    <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><UserIcon size='1rem' /></ListItemIcon>
                                    <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Account</Typography>
                                </ListItem>
                            </NavLink>
                        </Box>
                    </Card>
                </Box>
            )
            return (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <HashRouter>
                        <AppBar variant='elevation' position='sticky' style={{ display: "flex", flexDirection: "row", flex: 1, maxHeight: 64, zIndex: 1300 }}>
                            <Hidden lgUp>
                                <IconButton
                                    color="inherit"
                                    onClick={() => this.setState({ openNav: true })}
                                >
                                    <SvgIcon fontSize="small">
                                        <MenuIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Hidden>
                            <Hidden mdDown>
                                <div style={{ margin: "10px 20px 5px 20px" }}>
                                    <Link to='/admin' style={{display:'flex', alignItems:'center', gap : 10}}><img alt='logo' src={logo} width={40}></img> <Typography style={{color:'white'}}>Madmaps Admin</Typography> </Link>
                                </div>
                            </Hidden>
                            <div style={{ display: 'flex', flex: 1 }} />
                            <Toolbar>
                                <div style={{ margin: "6px 20px 7px 20px", display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
                                    <Box display='flex' flexDirection='row-reverse' style={{ gap: 8 }} component={ButtonBase} onClick={(e) => { this.setState({ anchorEl: e.currentTarget, menuOpen: true }) }}>
                                        <Typography variant='caption' color='inherit'>{this.state.userName}</Typography>
                                        {this.state.userPhoto ? <Avatar style={{ height: 32, width: 32 }} alt={this.state.userName} src={this.state.userPhoto} /> : <Avatar style={{ height: 32, width: 32 }} alt={this.state.userName}>{this.state.userName ? (this.state.userName).charAt(0) : null}</Avatar>}
                                    </Box>
                                    <Menu
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        keepMounted
                                        getContentAnchorEl={null}
                                        anchorEl={this.state.anchorEl}
                                        open={this.state.menuOpen}
                                    >
                                        <MenuItem onClick={this.handleLogout}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </Toolbar>
                        </AppBar>

                        <div style={{ display: 'flex', flex: 1 }}>
                            <div>
                                <Hidden lgUp>
                                    <Drawer
                                        anchor="left"
                                        classes={{ paper: classes.mobileDrawer }}
                                        onClose={() => this.setState({ openNav: false })}
                                        open={this.state.openNav}
                                        variant="temporary"
                                    >
                                        {content}
                                    </Drawer>
                                </Hidden>
                                <Hidden mdDown>
                                    <Drawer
                                        anchor="left"
                                        classes={{ paper: classes.desktopDrawer }}
                                        open
                                        variant="persistent"
                                    >
                                        {content}
                                    </Drawer>
                                </Hidden>
                            </div>
                            <div className={classes.wrapper}>
                                <div className={classes.contentContainer}>
                                    <div className={classes.content}>
                                        <Box style={{ margin: 20, flex: 1, display: 'flex' }}>
                                            <Route exact path='/settings' component={Settings} />
                                            <Route exact path='/users' component={Users} />
                                            <Route exact path='/account' component={Account} />
                                            <Route exact path='/' component={Madstuff} />
                                            <Route exact path='/wallet' component={Wallet} />
                                            <Route exact path='/ads' component={AdsManagement} />
                                            <Route exact path='/viewListings/:id' component={ViewListings} />
                                            {/* <Route exact path='/myhouses/data' component={HouseData} /> */}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashRouter>
                </div>
            )
        } else {
            return (
                <div>
                    {this.state.Redirect ? <Redirect to='/login' /> : null}
                </div>
            )

        }

    }

}



export default withStyles(useStyles)(withRouter(Dashboard))