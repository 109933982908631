import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyBBePcOQvVRHVFkfpJ3zQebuqwZtuctVRQ",
    authDomain: "mad-maps-307908.firebaseapp.com",
    databaseURL: "https://mad-maps-307908-default-rtdb.firebaseio.com",
    projectId: "mad-maps-307908",
    storageBucket: "mad-maps-307908.appspot.com",
    messagingSenderId: "890142983230",
    appId: "1:890142983230:web:0e503320340d1fb2e83d6f",
    measurementId: "G-ZJVFG1511P"
};

const app = firebase.initializeApp(firebaseConfig);
export default !firebase.apps.length ? app : firebase.app();