import {
  Card,
  Link,
  Typography,
  Breadcrumbs,
  TextField,
  CardMedia,
  AccordionSummary,
  FormControlLabel,
  AccordionDetails,
  Switch,
  ButtonBase,
  Grid,
  Box,
  Divider,
  Select,
  MenuItem
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import app from "../../firebase/firebaseConfig";
import Accordion from "@material-ui/core/Accordion";
import ImageUploader from "react-images-upload";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";


import Pagination from "@material-ui/lab/Pagination";

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';


class Madstuff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      open: false,
      openstories: false,
      categoryName: "",
      Locationname: "",
      address : "",
      Longitude: "",
      latitude: "",
      url : "",
      index: "",
      image: "",
      radioValue: "UploadImage",
      isUploadActive: true,
      UploadUrl: '',
      storyIndex: '',
      storyName: '',
      storyIdx: '',
      loading: false,
      openCategory: false,
      newCategoryName: '',
      categoryData: null,
      catData: null,
      isCategoryError: false,
      numberOfPage : [],
      itemsPerpage : 10,
      page : [],
      type : ""
    };
  }

  componentDidMount = () => {
    
    app
      .database()
      .ref(`ui/category`)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          this.setState({ data: snapshot.val(), page : new Array(snapshot.val().index.length).fill(1) });
          let nop = new Array(snapshot.val().length).fill(0)
          snapshot.val().index.map((k,i) => {
            let d_l = snapshot.val()[k].length
            nop[i] = Math.ceil(d_l/ this.state.itemsPerpage)

          })
          this.setState({numberOfPage : nop})
        }
      });
  };

  handleChange = (e) => {
    if (e.target.name === "newCategoryName") this.setState({categoryName : e.target.value}) 
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    this.handleClose();
    const { image, Locationname, Longitude, url, latitude, categoryName, index, catData } = this.state;
    console.log(url)
    var payload = {
      flag: true,
      image: image,
      location: { latitude: Number(latitude), longitude: Number(Longitude) },
      name: Locationname,
      
    };

    if (url !== "") {
      payload.story[0].url = url
    }

    if (catData?.length === 1 && !catData[0].image && catData[0].flag === false) {
      app.database().ref(`ui/category/${categoryName}/0`).set(payload);
    }
    if ((catData?.length === 1 && (catData[0].flag === true || catData[0].image)) || catData?.length > 1) {
      app.database().ref(`ui/category/${categoryName}/${index}`).set(payload);
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, openConfirm : false });
  };

  handleImageSelect = (e) => {
    this.setState({ loading: true });
    let storageRef = app.storage().ref();
    let name = e[0].name
    if (e[0].type === "image/png") {
      name = name.split('.png')[0]
      name = `${name}-${Date.now()}.png`
    }else if (e[0].type === "image/jpeg") {
      name = name.split('.jpeg')[0]
      name = `${name}-${Date.now()}.jpeg`
    } 
    
    let mountainImagesRef = storageRef.child(`/madStuff/${name}`);
    mountainImagesRef.put(e[0]).then(() => {
      mountainImagesRef.getDownloadURL().then((url) => {
        this.setState({ image: url, loading: false });
      });
    });
  };


  openformModal = (k) => {
    app
      .database()
      .ref(`ui/category/${k}`)
      .once("value", (snapShot) => {
        if (snapShot.exists()) {
          let data = snapShot.val();
          let index = data.length;
          this.setState({ index: index, catData: data });
        }
      });
    this.setState({ categoryName: k }, () => this.handleClickOpen());
  };

  handlestoriesClickOpen = () => {
    this.setState({ openstories: true });
  };

  handleCategoryClose = () => {
    this.setState({ openCategory: false });
  };

  handleCategoryOpen = () => {
    this.setState({ openCategory: true });
  };

  handleStoriesClose = () => {
    this.setState({ openstories: false });
  };
  openStoriesformModal = () => {
    this.handlestoriesClickOpen();
  };

  handleStoriesChange = (k, idx, index) => {
    console.log(index, this.state.page)
    if (this.state.page[index] >1) {
      idx = idx + (this.state.page[index] - 1)*this.state.itemsPerpage
    }
    this.setState({ storyIndex: idx, storyName: k }, () => this.handlestoriesClickOpen())
  };

  handleRadioChange = (event) => {
    this.setState({ radioValue: event.target.value }, () => {

      if (this.state.radioValue === "UploadImage") {
        this.setState({ isUploadActive: true });
      } else {
        this.setState({ isUploadActive: false });
      }
    })
  };

  handlestoriesSubmit = () => {
    const { UploadUrl, image, isUploadActive } = this.state
    const payload = {
      flag: true,
    }
    if (isUploadActive) {
      payload.image = image
    }
    if (!isUploadActive) {
      payload.video = UploadUrl
    }

    this.handleStoryDataUpload(payload)
  }

  handleStoryDataUpload = (payload) => {
    const { storyIndex, storyName } = this.state

    app
      .database()
      .ref(`ui/category/${storyName}/${storyIndex}/story`)
      .once("value", (snapShot) => {
        if (snapShot.exists()) {
          let data = snapShot.val();
          let index = data.length;
          this.setState({ storyIdx: index }, () => {
            if (data?.length === 1 && data[0].image === '' && data[0].flag === false) {
              app.database().ref(`ui/category/${storyName}/${storyIndex}/story`).set([payload]);
            }
            if ((data?.length === 1 && (data[0].flag === true || data[0].image !== '')) || data?.length > 1) {
              app.database().ref(`ui/category/${storyName}/${storyIndex}/story`).set([...data, payload]);
            }
            this.handleStoriesClose()
          });
        } else {
          let data = []
          let index = data.length
          app.database().ref(`ui/category/${storyName}/${storyIndex}/story`).set([payload]).then(() => this.handleStoriesClose())
        }
      });

  }

  

  handleCategoryAdd = () => {
    if (!this.state.newCategoryName) {
      this.setState({ isCategoryError: true })
    }
    if (this.state.newCategoryName && this.state.newCategoryName !== "") {
      this.setState({ isCategoryError: false })
      app
        .database()
        .ref(`ui/category`)
        .once("value", (snapShot) => {
          if (snapShot.exists()) {
            const data = snapShot.val()
            this.setState({ categoryData: data }, () => { this.updateCategoryData() })
          }
        });
    }
  }

  // Update Category info to database

  updateCategoryData = () => {
    const newKey = app.database().ref('ui/category').child(this.state.newCategoryName)
    console.log(this.state)
    let data = [{
      flag : true,
      image : this.state.image,
      location : {
        latitude : this.state.latitude,
        longitude : this.state.Longitude
      },
      name : this.state.Locationname,
      story : [{
        flag : true,
      }]
    }]


    if (this.state.type === 'video') {
      data[0].story[0].video = this.state.video
    } else if (this.state.type === 'image') {
      data[0].story[0].image = this.state.image
    } else if (this.state.type === 'collection') {
      data[0].story[0].image = this.state.image
      data[0].story[0].url = this.state.url
    }


    // Now put data to database and then set type in data
    newKey.set(data).then(() => {
      app.database().ref('ui/category/types').update({[this.state.categoryName] : this.state.type}).then(() => {
        app.database().ref('ui/category/index').once('value', (snapShot) => {
          let len = snapShot.val().length
          let d = snapShot.val()
          d.push(this.state.categoryName)
          app.database().ref('ui/category/index').update(d).then(() => {
            this.handleCategoryClose()
          })
        })
      })
    })
   
  }

  handleGetLatLng = (address) => {
    geocodeByAddress(address.label)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) => {
      this.setState({latitude : lat, Longitude : lng})
      
    }
    );
  }

  // Function to handle deletion of Category
  handleDeleteCat = (catName) => {
    this.setState({openConfirm : true, categoryName : catName})
  }

  // Function to handle confirm deletion of thwe category
  handleConfirmDelete = () => {
    let categoryName = this.state.categoryName

    app.database().ref('ui/category/index').once('value', (snapShot) => {
      if (snapShot.exists()) {
        let d = snapShot.val()
        let filtered = d.filter((value, index, arr) => {
          return value !== categoryName
        })
        app.database().ref('ui/category/index').set(filtered).then(() => {
          app.database().ref('ui/category').child(categoryName).remove().then(() => {
            app.database().ref('ui/category/types').child(categoryName).remove().then(() => this.setState({openConfirm : false}))
          })
        })
      }
    })
  }

  render() {
    
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link color="inherit" href="/#/ ">
            <Typography variant="caption">Home</Typography>
          </Link>
          <Typography variant="caption">Mad Library</Typography>
        </Breadcrumbs>
        <div title="Madstuff" style={{ display: "flex", flex: 1 }}>

          <Card
            style={{
              display: "flex",
              flex: 1,
              padding: 20,
              flexDirection: "column",
            }}
          >
            <Grid container direction="row" justifyContent="space-between" style={{marginBottom: '8px'}}>
              <Grid item  xs={9}>
                Categories
              </Grid>
              <Grid item xs={3} style={{display:'contents'}}>
                <Button variant="contained" style={{color:'white', backgroundColor:'#FDBA27'}} onClick={() => this.handleCategoryOpen()}>Add Category</Button>
              </Grid>
            </Grid>
            {this.state?.data?.index?.map((k, i) => {
              return (
                <Accordion key={k}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    aria-label="Expand"
                    id="additional-actions1-header"
                    aria-controls="additional-actions1-content"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography style={{ flex: 1 }}>{k}</Typography>

                    <FormControlLabel
                      aria-label="Button"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <div style={{display:'flex', gap:20}}>
                          <ButtonBase onClick={() => this.openformModal(k)}>
                            <AddCircleOutlineIcon />
                          </ButtonBase>
                          <Button variant="outlined" onClick={() => this.handleDeleteCat(k)}>Delete</Button>
                        </div>
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flex: 1,
                      flexWrap : 'wrap',
                      // flexDirection: "column",
                      gap: 10,
                    }}

                  >
                    {/* {console.log(this.state.data[k].slice((this.state.page[i] -1) * this.state.itemsPerpage, this.state.page[i] * this.state.itemsPerpage))}  */}
                    {this.state.data[k].slice((this.state.page[i] -1) * this.state.itemsPerpage, this.state.page[i] * this.state.itemsPerpage).map((l_k, l_i) => (
                      <>
                        {!l_k.name ? "" : (
                          <Card key={`${k}-${l_k.name}`} style={{width:"48%"}}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              // aria-controls="panel1a-content"
                              aria-label="Expand"
                              id="additional-actions1-header"
                              aria-controls="additional-actions1-content"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography style={{ flex: 1 }}>
                                {l_k.name}
                              </Typography>

                              <FormControlLabel
                                aria-label="Button"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                  <Switch
                                    checked={l_k["flag"]}
                                    onChange={() => {
                                      app
                                        .database()
                                        .ref(`ui/category/${k}/${l_i}`)
                                        .update({ flag: !l_k["flag"] });
                                    }}
                                    name={l_k.name}
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                  />
                                }
                              />
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex:1,
                                gap: 10,
                              }}
                            >
                              <div style={{ display: "flex", flex:1 }}>
                                <div style={{ padding: 10 }}>
                                  <CardMedia
                                    image={l_k.image}
                                    style={{
                                      width: 150,
                                      height: 100,
                                      flex:0.2,
                                      borderRadius: 15,
                                      boxShadow: "0 3px 6px #B9B9B9",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flex : 1,
                                    flexDirection: "column",
                                    rowGap: 12,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "max-content",
                                      alignItems: "center",
                                      gap: 53,
                                    }}
                                  >
                                    <Typography>Location Name</Typography>
                                    <TextField
                                      variant="outlined"
                                      value={l_k.name}
                                      onChange={(e) => {
                                        let data = this.state.data;
                                        data[k][l_i]["name"] = e.target.value;
                                        this.setState({ data: data });
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value !== null ||
                                          e.target.value !== ""
                                        ) {
                                          app
                                            .database()
                                            .ref(`ui/category/${k}/${l_i}`)
                                            .update({ name: e.target.value });
                                        }
                                      }}
                                    />
                                    
                                    {this.state.data.types[k] === "image" ? 
                                        <div>
                                          <Typography>Views : { l_k.story && l_k?.story[0]?.views ? Object.keys(l_k?.story[0]?.views)?.length : 0}</Typography>
                                        </div> : null
                                      }
                                  </div>
                                  {this.state.data.types[k] === "collection" ? 
                                    <div
                                      style={{
                                        display: "flex",
                                        height: "max-content",
                                        alignItems: "center",
                                        gap: 130,
                                        flex:1
                                      }}
                                    >
                                      <Typography >URL</Typography>
                                      <TextField
                                        variant="outlined"
                                        label="URL"
                                        style={{flex:1}}
                                        value={l_k?.story[0]?.url}
                                        onChange={(e) => {
                                          let data = this.state.data;
                                          data[k][l_i]["story"][0]['url'] =
                                            e.target.value;
                                          this.setState({ data: data });
                                        }}
                                        onBlur={(e) => {
                                          if (
                                            e.target.value !== null ||
                                            e.target.value !== ""
                                          ) {
                                            app
                                              .database()
                                              .ref(`ui/category/${k}/${l_i}/story/0`)
                                              .update({
                                                url:e.target.value,
                                              });
                                          }
                                        }}
                                      />
                                      
                                    </div> : null
                                  }

                                  {this.state.data.types[k] === "image" ?
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "max-content",
                                      alignItems: "center",
                                      gap: 100,
                                    }}
                                  >
                                    <Typography>Location</Typography>
                                    <TextField
                                      variant="outlined"
                                      label="Latitude"
                                      value={l_k?.location?.latitude}
                                      onChange={(e) => {
                                        let data = this.state.data;
                                        data[k][l_i]["location"]["latitude"] =
                                          e.target.value;
                                        this.setState({ data: data });
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value !== null ||
                                          e.target.value !== ""
                                        ) {
                                          app
                                            .database()
                                            .ref(`ui/category/${k}/${l_i}/location`)
                                            .update({
                                              latitude: parseFloat(e.target.value),
                                            });
                                        }
                                      }}
                                    />
                                    
                                    <TextField
                                      variant="outlined"
                                      label="Longitude"
                                      value={l_k?.location?.longitude}
                                      onChange={(e) => {
                                        let data = this.state.data;
                                        data[k][l_i]["location"]["longitude"] =
                                          e.target.value;
                                        this.setState({ data: data });
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value !== null ||
                                          e.target.value !== ""
                                        ) {
                                          app
                                            .database()
                                            .ref(`ui/category/${k}/${l_i}/location`)
                                            .update({
                                              longitude: parseFloat(e.target.value),
                                            });
                                        }
                                      }}
                                    />
                                  </div> : null }
                                </div>
                                
                              </div>
                              { this.state.data.types[k] === "image"  ? (
                                <Accordion key={k}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    // aria-controls="panel1a-content"
                                    aria-label="Expand"
                                    id="additional-actions1-header"
                                    aria-controls="additional-actions1-content"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography style={{ flex: 1 }}>
                                      Stories
                                    </Typography>
                                    <FormControlLabel
                                      aria-label="Button"
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      control={
                                        <ButtonBase>
                                          <AddCircleOutlineIcon
                                            onClick={() =>
                                              this.handleStoriesChange(k, l_i, i)
                                            }
                                          />
                                        </ButtonBase>
                                      }
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 10,
                                    }}
                                  >
                                    {l_k.story && l_k.story?.length > 0 && l_k.story.map((s_k, s_i) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          gap: 10,
                                        }}
                                      >
                                        <CardMedia
                                          style={{ width: 100, height: 150 }}
                                          image={s_k && s_k?.image ? s_k?.image : ""}
                                        />
                                        {s_k?.views ? <Typography>
                                          Views {Object.keys(s_k?.views)?.length}
                                        </Typography> : ''}
                                        <Switch
                                          checked={s_k["flag"]}
                                          onChange={() => {
                                            app
                                              .database()
                                              .ref(
                                                `ui/category/${k}/${l_i}/story/${s_i}`
                                              )
                                              .update({ flag: !s_k["flag"] });
                                          }}
                                          inputProps={{
                                            "aria-label": "secondary checkbox",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </AccordionDetails>
                                </Accordion>
                              ) : null}
                            </AccordionDetails>
                          </Card>
                        )}
                      </>
                    ))}
                    

                  </AccordionDetails>
                  <Divider />
                    <Box component="span" style={{display:'flex', flex:1, justifyContent:'center'}}>
                      <Pagination
                        count={this.state.numberOfPage[i]}
                        page={this.state.page[i]}
                        onChange={(event, value) => {
                          let data = this.state.page
                          data[i] = value
                          this.setState({page : data})
                        }}
                        defaultPage={1}
                        color="primary"
                        size="large"
                        showFirstButton
                        showLastButton
                        
                      />
                    </Box>
                </Accordion>
              );
            })}
          </Card>
        </div>
        <div style={{display:'flex', flex:1}}>
          {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
        Open form dialog
      </Button> */}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ background: "#FDBA27", color: "white" }}
            >
              <label className="modalheading">{"MadStuff Details"}</label>
            </DialogTitle>
            <DialogContent style={{minWidth:400}}>
              <DialogContentText>
                <b>Add Your {this.state.categoryName} Details.</b>
              </DialogContentText>
              <TextField
                margin="dense"
                id="Locationname"
                name="Locationname"
                label="Location Name"
                type="text"
                onChange={this.handleChange}
                fullWidth
              />
              {/* {console.log(, )} */}
              {this.state.categoryName !== '' && this.state.data.types[this.state.categoryName] === "video" ? 
                <div>
                  <TextField
                    margin="dense"
                    id="url"
                    name="Youtube Video key"
                    label="Youtube Video key (for ex : GhqQz0y_O1Y)"
                    onChange={this.handleChange}
                    fullWidth
                  />
                </div> : null
              }
              {this.state.categoryName !== '' && this.state.data.types[this.state.categoryName] === "collection" ? 
                <div>
                  <TextField
                    margin="dense"
                    id="url"
                    name="url"
                    label="URL (for ex : https://www.google.com)"
                    type="url"
                    onChange={this.handleChange}
                    fullWidth
                  />
                </div> : null
              }
              {this.state.categoryName !== '' && this.state.data.types[this.state.categoryName] === "image" ? 
                <div>
                <p>Search Location</p>
                <div>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCWA8SQYhCg5PW8JZFRvIuSWrvC5nSLYKc"
                    apiOptions={{ language: 'en', region: 'in' }}
                    minLengthAutocomplete={3}
                    withSessionToken
                    selectProps={{onChange : (value) => this.handleGetLatLng(value)}}
                  />
                </div>
                <TextField
                  margin="dense"
                  id="Longitude"
                  name="Longitude"
                  label="Longitude"
                  type="text"
                  value={this.state.Longitude}
                  onChange={this.handleChange}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="latitude"
                  name="latitude"
                  label="Latitude"
                  type="text"
                  value={this.state.latitude}
                  onChange={this.handleChange}
                  fullWidth
                /> </div> : null }
              <div className="row" style={{ marginTop: "3%", flexDirection:'column' }}>
                <div className="col-sm-6">
                  <label>Upload Thumbnail</label>
                </div>
                <div className="col-sm-6">
                  <ImageUploader
                    id="file"
                    placeholder="Upload Image"
                    type="file"
                    onChange={this.handleImageSelect}
                  />
                </div>
              </div>
              {this.state.loading ? (
                <Grid container spacing={2}>
                  <Grid item xs={3} />

                  <Grid item xs={9} style={{ color: "red" }}>
                    Uploading Image! Please wait.....
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal for Confirm Delete */}
          <Dialog
            open={this.state.openConfirm}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ background: "#FDBA27", color: "white" }}
            >
              <label className="modalheading">{"Confirm Delete"}</label>
            </DialogTitle>
            <DialogContent style={{minWidth:400}}>
              <DialogContentText>
                <p>You will loose all data of <b>{this.state.categoryName} </b> Category if you do this action. Please Confirm to perform this action.</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleConfirmDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* ---------------------------Stories Modal--------------------------------------       */}
        <div>
          <Dialog
            open={this.state.openstories}
            onClose={this.handleStoriesClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ background: "#3949ab", color: "white", minWidth: '30rem' }}
            >
              <label className="modalheading">{"MadStuff Details"}</label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Add Your Stories.</b>
              </DialogContentText>
              <FormControl component="fieldset">
                <FormLabel component="legend">Upload</FormLabel>
                <RadioGroup
                  aria-label="upload"
                  name="uploads"
                  value={this.state.radioValue}
                  onChange={(e) => this.handleRadioChange(e)}
                >
                  <FormControlLabel
                    value="UploadImage"
                    control={<Radio />}
                    label="Upload by Image"
                  />
                  <FormControlLabel
                    value="UploadByUrl"
                    control={<Radio />}
                    label="Upload by Url"
                  />
                </RadioGroup>
              </FormControl>

              {this.state.isUploadActive ? (
                <>
                  <div className="row" style={{ marginTop: "3%", flexDirection:'column' }}>
                    <div className="col-sm-6">
                      <label>Upload Image</label>
                    </div>
                    <div className="col-sm-6">
                      <ImageUploader
                        id="file"
                        placeholder="Upload Image"
                        type="file"
                        onChange={this.handleImageSelect}
                      />
                    </div>
                  </div>
                  {this.state.loading ? (
                    <Grid container spacing={2}>
                      <Grid item xs={3} />

                      <Grid item xs={9} style={{ color: "red" }}>
                        Uploading Image! Please wait.....
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <TextField
                  margin="dense"
                  id="latitude"
                  name="UploadUrl"
                  label="UploadUrl"
                  type="text"
                  onChange={this.handleChange}
                  fullWidth
                />
              )}

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleStoriesClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handlestoriesSubmit} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          {/* -------------------------Category Modal----------------------------------- */}

          <Dialog
            open={this.state.openCategory}
            onClose={this.handleCategoryClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <DialogTitle
              id="form-dialog-title"
              style={{ background: "#FDBA27", color: "white", minWidth: '30rem' }}
            >
              <label className="modalheading">{"Category"}</label>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <b>Add New Category.</b>
              </DialogContentText>

              <TextField
                margin="dense"
                id="newCategoryName"
                name="newCategoryName"
                label="Category Name"
                type="text"
                onChange={this.handleChange}
                fullWidth
              />
              <label htmlFor="select-type-cat">Select Type of Category</label>
              <Select
                value={this.state.type}
                fullWidth
                labelId="select-type-cat"
                label="Category Type"
                onChange={(e) => this.setState({type : e.target.value}) }
              >
                <MenuItem value={"video"}>Video</MenuItem>
                <MenuItem value={"image"}>Image</MenuItem>
                <MenuItem value={"collection"}>Collection</MenuItem>
              </Select>
              { this.state.type !== "" ? <div>
                <DialogContent style={{minWidth:450}}>
                  <DialogContentText>
                    <b>Add Your {this.state.categoryName} Details.</b>
                  </DialogContentText>
                  <TextField
                    margin="dense"
                    id="Locationname"
                    name="Locationname"
                    label="Location Name"
                    type="text"
                    onChange={this.handleChange}
                    fullWidth
                  />
                  {this.state.type === "video" ? 
                    <div>
                      <TextField
                        margin="dense"
                        id="url"
                        name="video"
                        label="Youtube Video key (for ex : GhqQz0y_O1Y)"
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div> : null
                  }
                  {this.state.type === "collection" ? 
                    <div>
                      <TextField
                        margin="dense"
                        id="url"
                        name="url"
                        label="URL (for ex : https://www.google.com)"
                        type="url"
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </div> : null
                  }
                  {this.state.type === "image" ? 
                    <div>
                    <p>Search Location</p>
                    <div>
                      <GooglePlacesAutocomplete
                        apiKey="AIzaSyCWA8SQYhCg5PW8JZFRvIuSWrvC5nSLYKc"
                        apiOptions={{ language: 'en', region: 'in' }}
                        minLengthAutocomplete={3}
                        withSessionToken
                        selectProps={{onChange : (value) => this.handleGetLatLng(value)}}
                      />
                    </div>
                    <TextField
                      margin="dense"
                      id="Longitude"
                      name="Longitude"
                      label="Longitude"
                      type="text"
                      value={this.state.Longitude}
                      onChange={this.handleChange}
                      fullWidth
                    />
                    <TextField
                      margin="dense"
                      id="latitude"
                      name="latitude"
                      label="Latitude"
                      type="text"
                      value={this.state.latitude}
                      onChange={this.handleChange}
                      fullWidth
                    /> </div> : null }
                  <div className="row" style={{ marginTop: "3%", flexDirection:'column' }}>
                    <div className="col-sm-6">
                      <label>Upload Thumbnail</label>
                    </div>
                    <div className="col-sm-6">
                      <ImageUploader
                        id="file"
                        placeholder="Upload Image"
                        type="file"
                        onChange={this.handleImageSelect}
                      />
                    </div>
                  </div>
                  {this.state.loading ? (
                    <Grid container spacing={2}>
                      <Grid item xs={3} />
                      <Grid item xs={9} style={{ color: "red" }}>
                        Uploading Image! Please wait.....
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </DialogContent>
              </div> : null

              }

              {this.state.isCategoryError ? (
                <Grid container spacing={2}>
                  <Grid item xs={3} />

                  <Grid item xs={9} style={{ color: "red" }}>
                    Please Enter Category Name!
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCategoryClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleCategoryAdd} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default Madstuff;
