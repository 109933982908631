import {
    Card,
    Link,
    Typography,
    Breadcrumbs,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    Modal,
    Box,
    Dialog,
    DialogTitle, DialogActions, TextField
} from "@material-ui/core";
import React, {Component} from "react";
import app from "../../firebase/firebaseConfig";
import {ExpandMore} from "@material-ui/icons";
import Button from "@material-ui/core/Button";



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
class ViewListings extends Component {
    get pathName() {
        return this._pathName;
    }

    set pathName(value) {
        this._pathName = value;
    }

    constructor(props) {
        super(props);
        this.state = {
            views: {},
            myListing: {},
            flag:false,
            open: false,
            count:0,
            listingId:'',
            listingStoryId:''
        }
        this.hideStory= this.hideStory.bind(this);

        this.handleOpen= this.handleOpen.bind(this);
        this.handleClose= this.handleClose.bind(this);

        this._pathName = '';
        this.pathName = this.props.match.params.id;
    }

    /**
     * ComponentDidMount
     */
    componentDidMount = () => {
        let userId = this.pathName
        app.database().ref(`users/${userId}/listings`).once("value", (snapshot) => {
            if (snapshot.exists()) {
                this.setState({myListing: snapshot.val()})
                new Promise((resolve, reject) => this.handleShowViews(resolve, Object.keys(snapshot.val()))).then(() => this.setState({render: true}))
            }
        })
    };

    /**
     *Views functions
     */

    handleShowViews = (resolve, geoHash) => {
        var viewsData = {}
        geoHash.length !== 0 && geoHash.map((k, i) =>
            app.database().ref(`Analytic/bs_views/${k}`).once("value", (snapshot) => {
                    if (snapshot.exists()) {
                        viewsData[k] = snapshot.val()
                        if (geoHash.length - 1 === i) {
                            this.setState({views: viewsData})
                            resolve("OK")
                        }
                    } else {
                        resolve("OK")
                    }
                }
            ))
    }
    handleReturnViews = (g_id, a_id) => {
        // let views = this.state.views[g_id][a_id]
        let views = 0
        if (g_id in this.state.views && a_id in this.state.views[g_id]) {
            this.state.views[g_id][a_id].count ?
                views = this.state.views[g_id][a_id].count
                : views = Object.keys(this.state.views[g_id][a_id]).length;
        }

        return (
            <TableCell align="center">{views}</TableCell>
        )
    }

    /**
     *Hide Story function
     */
    hideStory = (storyId, listingId) => {
        console.log(listingId);
        let userId = this.pathName;
        this.setState(prevState => ({
            flag: !this.state.flag
        }));
        // app.database().ref(`users/${userId}/listings/${storyId}/listingStory/${listingId}`).update({flag : this.state.flag});
    }

    handleOpen = (g_id,a_id) => {
        console.log(g_id)
        if(this.state.views[g_id]){
            console.log(this.state.views[g_id][a_id].count)
            this.setState({
                count:this.state.views[g_id][a_id].count
            })
        }
        this.setState({
            listingId: g_id,
            listingStoryId: a_id
        })

        this.setState({open: true})
    }
    handleChange = (e) => {
        this.setState({
            count:e.target.value
        })

    }
    handleClose = () => {
        console.log(this.state.count);
        this.setState({open: false})
        app.database().ref(`Analytic/bs_views/${this.state.listingId}/${this.state.listingStoryId}`).update({count : this.state.count})
    }


    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", flex: 1}}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <Link color="inherit" href="/#/">
                        <Typography variant="caption">Home</Typography>
                    </Link>
                    <Link color="inherit" href="/admin#/wallet">
                        <Typography variant="caption">Wallet Management</Typography>
                    </Link>
                    <Link color="inherit" href="/admin#/viewListings">
                        <Typography variant="caption">View Listings</Typography>
                    </Link>
                </Breadcrumbs>
                <Grid>
                    {Object.keys(this.state.myListing) !== 0 && Object.keys(this.state.myListing).map((k, i) => {
                        return (
                            <div key={k} style={{flex: 1, display: 'flex'}}>
                                {this.state.myListing[k]?.listingStory && Object.keys(this.state.myListing[k]?.listingStory).map((c_k, c_i) => {
                                        return (
                                            <div key={c_k}
                                                 style={{flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                                                <Card style={{padding:'10px '}}>
                                                    <div expandIcon={<ExpandMore/>}>
                                                        {this.state.myListing[k].locationName}
                                                    </div>
                                                    <div style={{margin:'10px'}}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Title</TableCell>
                                                                        <TableCell align="center">Description</TableCell>
                                                                        <TableCell align="center">Logo</TableCell>
                                                                        <TableCell align="center">Offers</TableCell>
                                                                        <TableCell align="center">Photo</TableCell>
                                                                        <TableCell align="center">Template</TableCell>
                                                                        <TableCell align="center">Views</TableCell>
                                                                        <TableCell align="center">Story Hidden</TableCell>
                                                                        <TableCell></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow
                                                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                        <TableCell component="th" scope="row">
                                                                            {this.state.myListing[k].listingStory[c_k].title}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center">{this.state.myListing[k].listingStory[c_k].description}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img style={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                                 src={this.state.myListing[k].listingStory[c_k].logo}/>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center">{this.state.myListing[k].listingStory[c_k].offers}</TableCell>
                                                                        <TableCell align="center">
                                                                            <img style={{
                                                                                width: '100px',
                                                                                height: '100px',
                                                                                objectFit: 'cover'
                                                                            }}
                                                                                 src={this.state.myListing[k].listingStory[c_k].photo}/>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="center">{this.state.myListing[k].listingStory[c_k].template}</TableCell>
                                                                        {this.handleReturnViews(k, c_k)}
                                                                        {this.state.myListing[k].listingStory[c_k].flag ? <TableCell>Hidden</TableCell> : <TableCell>Shown</TableCell>}
                                                                        {/*<TableCell>*/}
                                                                        {/*    <Button*/}
                                                                        {/*        variant="contained"*/}
                                                                        {/*        color="primary"*/}
                                                                        {/*        size="small"*/}
                                                                        {/*        onClick={() => this.hideStory(k,c_k)}*/}
                                                                        {/*    >*/}
                                                                        {/*        {this.state.myListing[k].listingStory[c_k].flag ? "Show Story" : "Hide Story"}*/}
                                                                        {/*    </Button>*/}
                                                                        {/*</TableCell>*/}
                                                                        <TableCell>
                                                                            <Button variant="outlined" onClick={() => this.handleOpen(k,c_k)}>
                                                                                Edit Count
                                                                            </Button>
                                                                            <Dialog
                                                                                open={this.state.open}
                                                                                aria-labelledby="alert-dialog-title"
                                                                                aria-describedby="alert-dialog-description"
                                                                            >
                                                                                <DialogTitle id="alert-dialog-title">
                                                                                    {"Increase the count of the story."}
                                                                                </DialogTitle>
                                                                                <TextField id="outlined-basic" label="Count" value={this.state.count} variant="outlined"
                                                                                           onChange={this.handleChange}/>

                                                                                <DialogActions>
                                                                                    <Button onClick={() => this.handleClose()} autoFocus>
                                                                                        Submit
                                                                                    </Button>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    }
                                )}
                            </div>)
                    })}

                </Grid>

            </div>
        );
    }


}

export default ViewListings;
