import { TextField,  InputLabel, Card, AccordionDetails, AccordionSummary,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button, IconButton 
} from '@material-ui/core'
import React from 'react';
import Pagination from "@material-ui/lab/Pagination";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import {
    Close as CloseIcon,
} from '@material-ui/icons';

class ShowDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            credits : 0,
            name : "",
            open: false,
            stories : [],
            numberOfPage : [],
            itemsPerpage : 10,
            page : 1,
            views : {},
            render : true,
            myListing : {},
            markerData : {},
            increaseViews : 0,
            sId : "",
            pId : ""
        }
    }


    componentDidMount = () => {
        this.setState({credits : this.getter('credit'), name : this.getter('name'), markerData:this.getter('data').markers})
        this.getStoryList()
        this.props.app.database().ref(`users/${this.state.data.id}/listings`).once("value", (snapshot) => {
            if (snapshot.exists()) {
                this.setState({myListing: snapshot.val()})
                new Promise((resolve, reject) => this.handleShowViews(resolve, Object.keys(snapshot.val()))).then(() => {
                    this.setState({render: true})})
            }
        })
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    // views functions
    handleShowViews = (resolve, geoHash) => {
        var viewsData = {}
        geoHash.length !== 0 && geoHash.map((k, i) =>
            this.props.app.database().ref(`Analytic/bs_views/${k}`).once("value", (snapshot) => {
                    if (snapshot.exists()) {
                        viewsData[k] = snapshot.val()
                        if (geoHash.length - 1 === i) {
                            this.setState({views: viewsData})
                            resolve("OK")
                        }
                    } else {
                        resolve("OK")
                    }
                }
            ))
    }

    handleIncreaseViews = () => {
        if (this.state.sId && this.state.pId) {
            this.props.app.database().ref(`Analytic/bs_views/${this.state.pId}/${this.state.sId}`).update({count: parseInt(this.state.increaseViews)}).then(() => {
                this.setState({open : false, increaseViews:0, sId:"", pId:""})
            })
        }
    }

    handleReturnViews = (p_id, a_id) => {
        // let views = this.state.views[g_id][a_id]
        let views = 0
        if (p_id in this.state.views && a_id in this.state.views[p_id]) {
            this.state.views[p_id][a_id].count ?
                views = this.state.views[p_id][a_id].count
                : views = Object.keys(this.state.views[p_id][a_id]).length;
        }
        return (
            <Typography align="left" style={{flex:0.6}}>{views}</Typography>
        )
    }

    handleClose = () => {
        this.setState({open : false})
    }

    handleOriginalViews = (p_id, a_id) => {
        let views = 0
        if (p_id in this.state.views && a_id in this.state.views[p_id]) {
                views = Object.keys(this.state.views[p_id][a_id]).length -1
        }
        return (
            <Typography align="left" style={{flex:0.6}}>{views}</Typography>
        )
    }

    handleStoryVisibility = (p_id, a_id, flag) => {
        this.props.updateData(p_id, a_id, flag);
        let m_d = this.state.markerData
        m_d[p_id]["listingStory"][a_id].flag = flag
        this.setState({markerData:m_d})
        this.props.app.firestore().doc('madMap/markers').set({
            markers : {
                [p_id] : {
                    listingStory : {
                        [a_id] : {
                            flag : flag
                        }
                    }
                }
            }
        }, {merge : true})
    }

    handleStoryVisibilityPreview = (p_id, a_id) => {
        var flag = true
        if (p_id in this.state.markerData && this.state.markerData[p_id]?.listingStory) {
            flag = this.state.markerData[p_id]?.listingStory[a_id]?.flag
            if (flag === undefined) {
                flag = false
            }

        }
        return (
            <IconButton onClick={() => this.handleStoryVisibility(p_id, a_id, !flag)}>{flag ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon /> }</IconButton>
        )
       
    }

    getStoryList = () => {
        let s = this.getter('listings');
        let stories = []
        Object.entries(s).map((k,i) => {
            if (k[1]["listingStory"]) 
            Object.entries(k[1]["listingStory"] ).map((c_k, c_i) => {
                let d = c_k[1]
                d.pId = k[0]
                d.sId = c_k[0]
                d.address = k[1]["address"]
                d.lName = k[1]["locationName"]
                stories.push(d)
            })
        })
        this.setState({stories : stories, numberOfPage : Math.ceil(stories.length/this.state.itemsPerpage)})
    }

    render = () => {
        if (this.state.render) {
            return (
                <div style={{display:'flex', flex:1, flexDirection:'column', gap:10}}>
                    <div style={{display: 'flex', flex:0.5, alignItems:'center'}}>
                        <InputLabel style={{flex:0.5}}>Name</InputLabel>
                        <TextField variant="outlined" value={this.state.name} onChange={(e) => this.setState({name : e.target.value})}></TextField>
                    </div>
                    <div style={{display: 'flex', flex:0.5, alignItems:'center'}}>
                        <InputLabel style={{flex:0.5}}>Credits</InputLabel>
                        <TextField variant="outlined" value={this.state.credits} onChange={(e) => this.setState({credits : e.target.value})}></TextField>
                    </div>
                    <Card>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor:"#FDBA27"}}
                            >
                            <Typography style={{color:'white'}}>Ads History</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flex:1, flexDirection:'column'}}>
                            {this.state.stories.length !== 0 ? 
                                <>
                                    {this.state.stories.map((k,i) => {
                                        return(
                                            <div style={{display:'flex', flex:1, padding:20, borderBottom: "1px solid #D4D4D4", gap:20}}>
                                                <div style={{display:'flex', flexDirection:'column', flex:0.5, justifyContent:'center', gap:8}}>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:10}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Template</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.template}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Story Title</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.title}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Story Description</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.description}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Offers</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.offers}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Location Name</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.lName}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Address</Typography>
                                                        <Typography align="left" style={{flex:0.6}}>{k.address}</Typography>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Original Views</Typography>
                                                        {this.state.views ? this.handleOriginalViews(k.pId, k.sId) : <Typography align="left" style={{flex:0.6}}>0</Typography>}
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:"space-between", gap:20}}>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:18, flex : 0.4}}>Total Views</Typography>
                                                        
                                                        {this.state.views ? this.handleReturnViews(k.pId, k.sId) : <Typography align="left" style={{flex:0.6}}>0</Typography>}
                                                    </div>
                                                </div>
                                                <div style={{flex:0.35, gap:10, display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                                                    <div >
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:15}}>Logo Preview</Typography>
                                                        <img src={k.logo} style={{width: '100px',
                                                                                    height: '100px',
                                                                                    objectFit: 'cover'}}/>
                                                    </div>
                                                    <div>
                                                        <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:15}}>Background Preview</Typography>
                                                        <img src={k.photo} style={{width: 120,
                                                                                    height: 259,
                                                                                    objectFit: 'cover'}}/>
                                                    </div>
                                                </div>
                                                <div style={{flex : 0.15, display:'flex', flexDirection:'column'}}>
                                                    <Typography style={{fontFamily:'Roboto', fontWeight:500, fontSize:16}}>Actions</Typography>
                                                    <div style={{display:'flex', flexDirection:'row',  justifyContent:'space-between',alignItems:'center'}}>
                                                        <Typography>Visibility</Typography>
                                                        {this.handleStoryVisibilityPreview(k.pId, k.sId)}
                                                    </div>
                                                    <div style={{display:'flex', flexDirection:'row',  justifyContent:'space-between',alignItems:'center'}}>
                                                        <Typography>Increase Views</Typography>
                                                        <IconButton onClick={() => this.setState({open:true, pId : k.pId, sId : k.sId})}><ImportExportOutlinedIcon /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        
                                    })}
                                </>
                            : <Typography>No Story Published</Typography>}
                            {this.state.numberOfPage > 1 ?
                                <Pagination
                                    style={{flex:1, display:'flex', justifyContent:'center'}}
                                    count={this.state.numberOfPage}
                                    page={this.state.page}
                                    onChange={(event, value) => {
                                        this.setState({page : value})
                                        }}
                                    defaultPage={1}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                /> : null }
                        </AccordionDetails>
                    </Card>
                    {/* Open Dialog Window */}
                    <Dialog
                        // style={{display:'flex', flex:1}}
                        open={this.state.open}
                        maxWidth = {"xs"}
                        onClose={this.handleClose}
                        fullWidth={true}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            style={{ background: "#FDBA27", color: "white", display:'flex', justifyContent:'space-between', alignItems:'center' }}
                            >
                            <label style={{color:'white'}}>Increase Views</label>
                            <IconButton size="small" onClick={this.handleClose} > <CloseIcon style={{color:'white'}} /></IconButton>
                        </DialogTitle>
                        <DialogContent style={{minHeight:100, display:'flex', flexDirection:'column',gap:20, paddingBottom:40}}>
                            <Typography>Enter Amount of Views to be shown on Ad</Typography>
                            <TextField variant="outlined" onChange={(e) => this.setState({increaseViews : e.target.value})} />
                            <Button variant='outlined' onClick={() => this.handleIncreaseViews()} endIcon={<DoneOutlinedIcon />} > Confirm </Button>
                        </DialogContent>

                    </Dialog>
                </div>
            )
        } else {
            return (
                <div>
                    Loading ...
                </div>
            )
        }
        
    }
}

export default ShowDetails